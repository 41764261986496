<template>
    <header v-if="currentroute != 'login' && currentroute != 'register'">
        <!-- LOGO -->
        <div class="container flex justify-around">
            <div class="hidden sm:flex mt-2">
                <a href="/">
                     <img class="w-40 md:w-125px" :src="baseUrl+'/default/images/logo_meu-amigo-oculto.png'">       
                </a>
                <ul v-if="this.user.id == 1" class="ml-3 text-primary-dark text-sm leading-6">
                    <li v-bind:class="{ 'currentLink': currentroute == 'admin-index' }">
                        <a href="/admin">admin</a>
                    </li>
                </ul>
            </div>

            <!-- NAV -->
            <div class="items-end text-primary-dark flex-1 md:flex">
                <nav class="flex-1">
                    <ul class="flex flex-1 justify-around text-xl sm:text-sm sm:justify-end">
                        <template v-if="!isauth">
                            <li class="px-4">
                                <a href="/login">
                                    <span class="icon-content">
                                    <i class="fa-solid fa-right-to-bracket"></i>
                                    <small>entrar</small>
                                    </span>
                                </a>
                            </li>
                            <li class="px-4">
                                <a href="/register">
                                    <span class="icon-content">
                                    <i class="fa-solid fa-user-plus"></i>
                                    <small>cadastrar</small>
                                    </span>
                                </a>
                            </li>
                        </template>
                        <template v-if="isauth">
                            <li class="px-4">
                                <a data-tooltip-target="tooltip-home" href="/painel">
                                    <span v-bind:class="{ 'currentLink': currentroute == 'dashboard' }" class="icon-content hover:opacity-70">
                                        <i class="fa-solid fa-house"></i>
                                        <small>painel</small>
                                    </span>
                                </a>
                            </li>
                            <li class="px-4">
                                <a href="/grupo" :class="{'blob': invites.length && currentroute != 'groups'}">
                                    <span v-bind:class="{ 'currentLink': currentroute == 'groups' }" class="icon-content hover:opacity-70">
                                        <i class="fa-solid fa-users"></i>
                                        <small>grupos</small>
                                    </span>
                                </a>
                            </li>
                            <li class="px-4">
                                <a href="/presente">
                                    <span v-bind:class="{ 'currentLink': currentroute == 'gift' }" class="icon-content hover:opacity-70">
                                        <i class="fa-solid fa-gift"></i>
                                        <small>presentes</small>
                                    </span>
                                </a>
                            </li>
                            <li class="px-4">
                                <a href="/perfil">
                                    <span v-bind:class="{ 'currentLink': currentroute == 'user.view' }" class="icon-content hover:opacity-70">
                                        <i v-if="user.image" class="border-2 rounded-full border-primary-dark" :style="'background-image: url('+user.image+')'"></i>
                                        <small class="user-txt">perfil</small>
                                    </span>
                                </a>
                            </li>
                        </template>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        props: ['currentroute', 'isauth', 'datauser'],
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                user: '',
                invites: [],
            }
        },
        mounted() {
            if(this.isauth) this.user = JSON.parse(this.datauser);
            this.getInvites();
        },
        methods: {
            getInvites() {

                let url = `${this.baseUrl}/api/v1/user/invite`;
                axios.get(url)
                    .then(response => {
                        this.invites = response.data;
                    })
                    .catch(errors => {
                        if(process.env.APP_DEBUG) console.log('error: ', errors);
                    })

            },
        }
    }
</script>
