<template>
     <div class="flex pt-8 pb-1 pl-8 pr-2">
        
        <button class="btn border-t-main-gray mr-4" @click="backTo()"> <i class="fa-solid fa-arrow-left mr-2"></i> Voltar</button>
        
        <div class="bg-primary-dark rounded-full">
            <i class="fa-solid fa-question text-white p-4 px-5"></i>
        </div>
        <div class="ml-2">
            <h3>Quem sou eu?</h3>
            <p class="text-sm">Converse com seu amigo oculto</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['groupSlug'],
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
            }
        },
        methods: {
            backTo () {
                window.location.href = `${this.baseUrl}/grupo/${this.groupSlug}`;
            },
        }
    }
</script>
