<template>
    <ul class="text-gray-900 leading-6 text-center">
        <li><h2>{{user.name}}</h2></li>
        <li class="text-sm"><i class="fa-solid fa-address-card"></i> {{user.slug}}</li>
        <li class="text-sm"><i class="fa-solid fa-envelope"></i> {{user.email}}</li>
    </ul>

    <hr class="my-4">
    
    <ul class="flex pb-6 justify-around md:justify-start">
        <template v-for="(tab, index) in tabs" :key="index">
            <li class="cursor-pointer transition py-2 px-4 w-1/3 md:w-2/12 text-center" :class="activeTab==index ? 'bg-primary rounded-md text-white md:text-primary md:bg-white' : ' text-gray-300'" @click="activeTab = index">
                <i class="fa-solid text-xl" :class="{'fa-user': index === 0, 'fa-people-group': index === 1, 'fa-gift': index === 2}"></i>
                <span class="hideTextMobile">{{tab}}</span>
            </li>
        </template>
    </ul>

    <div v-if="activeTab == 0" class="w-full bg-gray-50 rounded-sm p-3">
        <p class="text-primary-dark text-xl font-medium">Sobre mim</p>
        <p class="text-gray-600">{{user.description}}</p>
        <p v-if="user.dob" class="text-gray-600"><i class="fa-solid fa-birthday-cake text-sm"></i> {{user.dob}}</p>
    </div>    

    <div v-if="activeTab == 1" class="w-full bg-gray-50 rounded-sm p-3">
        <MyGroups />
    </div>    
    <div v-if="activeTab == 2" class="w-full bg-gray-50 rounded-sm p-3">
        <MyGifts />
    </div>   
</template>
<script>
    import MyGroups from "@/components/includes/MyGroups";
    import MyGifts from "@/components/includes/MyGifts";

    export default {
        props: {
            user: '',
            tab: {
                default: ''
            }
        },
        components: {
            MyGroups,
            MyGifts
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                activeTab: 0,
                tabs: [
                    "Sobre",
                    "Grupos",
                    "Presentes",
                ]
            }
        },
        mounted() {
            if(this.$props.tab <= 2) this.activeTab = this.$props.tab;
        }
    }
</script>