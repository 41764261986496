<template>
    <div class="container">
        <notify-component v-if="showNotify" :alert="dataNotify"></notify-component>

        <button class="btn border-t-main-gray mt-10 mb-3" @click="backTo()"> <i class="fa-solid fa-arrow-left mr-2"></i> Voltar</button>
        
        <div class="form-image bg-center bg-cover h-72 mt-8 rounded-t-lg p-0" :style="'background-image: url('+imagePreview+')'">
            <label class="flex flex-col justify-center h-full">
                <template v-if="imagePreview">
                    <button class="hover:text-white hover:bg-opacity-20 hover:bg-black h-full font-semibold" v-if="imagePreview" @click="imagePreview = ''"><i class="fas fa-redo-alt"></i> alterar</button>
                </template>
                <template v-else>
                    <div class="hover:text-primary-dark font-semibold flex flex-col justify-center h-full">
                        <i class="fa-solid fa-camera"></i>
                        <span>Selecione uma imagem (jpg ou png)</span>
                        <input v-if="!imagePreview" type="file" accept=".jpg, .jpeg, .png" class="" id="groupImage" aria-describedby="groupImage" placeholder="Selecione uma imagem" :value="imagePreview" @change="loadImagem($event)">
                    </div>
                </template>
            </label>
        </div>
        
        <div class="form-field" :class="(group.name && group.name.length < 4 && group.name != '') || (btnClicked && group.name === '') ? 'error' : 'active'">
            <label for="name">Nome do grupo</label>
            <input maxlength="50" type="text" class="input-form" id="groupName" aria-describedby="groupName" v-model="group.name">
            <small v-if="group.name && group.name.length < 4 && group.name != ''" class="text-secondary-dark">No mínimo 4 caracteres</small>
            <small v-if="btnClicked && group.name === ''" class="text-secondary-dark">O campo nome do grupo é obrigatório</small>
        </div>
        <div class="form-field" :class="{'active': group.description}">
            <label for="descricao">Descrição do grupo</label>
            <textarea maxlength="100" class="input-form resize-none" id="groupDescription" aria-describedby="groupDescription" v-model="group.description"></textarea>
        </div>
        <div class="form-field active">
            <label for="data">Data do evento</label>
            <input :disabled="noEventDate" type="date" class="input-form" id="groupevent_date" :min="today" aria-describedby="groupevent_date" aria-placeholder="Data" v-model="group.event_date">
            <small><input type="checkbox" name="no_event_date" v-model="noEventDate"> data ainda não definida</small>
        </div>

        <div class="flex justify-end mb-20">
            <button class="btn border-t-main-gray mr-3" @click="backTo()">Cancelar</button>
            <button class="btn btn-primary" @click="saveGroup()">Salvar</button>
        </div>
    </div>
</template>

<script>
    import base from '@/base';
    import Swal from 'sweetalert2';
    
    export default {
        props: ['data_group','data_edit'],
        
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                imagePreview: '',
                group: [],
                btnClicked: false,
                showNotify: false,
                dataNotify: {},
                noEventDate: true,
                today: new Date().toLocaleDateString('sv')
            }
        },
        mounted() {
            base.labelAction();
            this.group = JSON.parse(this.data_group);
            this.noEventDate = this.group.event_date ? false : true;
            this.imagePreview = this.group.image;
        },
        methods: {
            loadImagem(e) {
                if(this.imagePreview) this.imagePreview = '';
                if(e.target.files.length > 0){
                    this.imagePreview = URL.createObjectURL(e.target.files[0]);
                    this.group.image = e.target.files;
                }
            },
            clearForm() {
                this.group.image = '';
                this.imagePreview = '';
                this.group.name = '';
                this.group.description = '';
                this.group.event_date = '';
            },

            saveGroup() {
                if(this.validateValues()) {
                    this.btnClicked = true;
                    return;
                }

                let url = `${this.baseUrl}/api/v1/group/${this.group.slug}`;
                
                let formData = new FormData();
                formData.append('_method', 'patch');
                formData.append('name', this.group.name);
                formData.append('image', this.group.image[0]);
                formData.append('description', this.group.description);
                formData.append('event_date', this.noEventDate ? '' : this.group.event_date);

                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                axios.post(url, formData, config)
                    .then(response => {

                        const Toast = Swal.mixin({
                                toast: true,
                                position: 'top',
                                showConfirmButton: false,
                                timer: 2500,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: response.data.type,
                                title: 'Grupo atualizado com sucesso',
                            }).then(()=>{
                                this.backTo();
                            })
                    })
                    .catch(errors => {
                        if(process.env.APP_DEBUG) console.log('errors: ', errors);

                        const Toast = Swal.mixin({
                                toast: true,
                                position: 'top',
                                showConfirmButton: false,
                                timer: 2500,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'error',
                                title: 'Ops! Algo deu errado',
                                html: 'Tente novamente em alguns instantes.'

                            })
                    })
            },
            validateValues() {
                if (this.group.name) {
                    return false;
                }
                return true;
            },
            backTo() {
                window.location.href = `${this.baseUrl}/grupo/${this.group.slug}`;
            },
        },
    }
</script>
