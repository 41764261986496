<template>
    <Carousel :mouseDrag="true" v-if="groups.length" :breakpoints="breakpointsGroup" :aria-label="'groups'">
        <Slide v-for="(group, index) in groups" :key="index">
            <div class="effect-hover carousel__item card shadow-none border-gray-200 mx-1 w-full cursor-pointer" @click="goToGroupPage(group.slug)">
                <div class="card-header bg-primary bg-center h-20 bg-no-repeat bg-transparent" :style="'background-image: url('+group.image+');'">
                    <div v-if="group.event_date" class="bg-white flex flex-col justify-center float-right -mt-3 mr-1 p-2 rounded-md w-16 h-full text-secondary">
                        <span class="text-center text-sm">{{group.event_day}}</span>
                        <span class="text-center text-sm">{{group.event_month}}</span>
                    </div>
                </div>
                <div class="card-body text-xs md:text-sm text-left h-4">
                    <p class="text-gray-500 py-2 truncate">{{group.name}}</p>
                </div>
                <div v-if="config.showUsers" class="flex">
                    <div class="group-avatar">
                        <div class="" v-for="(user, index) in group.users" :key="index">
                            <a :href="baseUrl+'/perfil/'+user.slug" v-if="index <= 2">
                                <img :src="user.image" alt=""/>
                            </a>
                        </div>
                    </div>
                    <span v-if="group.users.length > 2" class="mt-3 text-gray-500 text-xs md:text-sm" :class="{'ml-5': group.users?.length === 4, 'ml-20': group.users?.length > 4}">+{{group.users.length - 3 }}</span>
                </div>
            </div>
        </Slide>

        <template #addons>
            <Navigation v-if="groups.length > 2" />
        </template>
    </Carousel>
    <div v-else class="text-sm">
        <p>
            <small>
                {{ userSlug ? 'Seu amigo' : 'Você'}} não está participando de nenhum grupo no momento.<br>
                <span v-if="!userSlug">Clique <a class="btn-link" :href="baseUrl+'/grupo'">aqui</a> para procurar um grupo ou criar o seu.</span>
            </small>
        </p>
    </div>
</template>

<script>
    import 'vue3-carousel/dist/carousel.css';
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
    export default {
        props: {
            config: {
                type: Object,
                default: function() {
                    return {
                        type: 'lg',
                        showUsers: true,
                        showEventDate: true
                    };
                }
            }
        },
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
        },
        data() {
            return {
                groups: {},
                baseUrl: process.env.MIX_APP_URL,
                currentRoute: window.location.pathname.replace('/', ''),
                userSlug: '',
                breakpointsGroup: {
                    100: {
                        itemsToShow: 1.3,
                    },
                    400: {
                        itemsToShow: 1.2,
                    },
                    700: {
                        itemsToShow: this.$props.config.type == 'sm' ? 5.1 : 2.5,
                    },
                },
            }
        },
        mounted() {
            this.checkUserSlug();
        },
        methods: {
            checkUserSlug() {
                const currentUrl = window.location.href;
                const perfilParam = currentUrl.split('/perfil/')[1];
                this.userSlug = perfilParam ?? '';
                this.getUserGroups();
            },
            goToGroupPage(slug) {
                window.location.href = `${this.baseUrl}/grupo/${slug}`;
            },

            getUserGroups() {
                let url = `${this.baseUrl}/api/v1/user/groups/${this.userSlug}`;
                axios.get(url)
                    .then(response => {
                        this.groups = response.data;
                    })
                    .catch(errors => {
                        if(process.env.APP_DEBUG) console.log('error: ', errors);
                    })
            },
        }
    }
</script>
<style>
    .carousel__item {
        min-height: 200px;
        width: 100%;
    }

    .carousel__slide {
        padding: 5px;
    }

    .carousel__viewport {
        overflow: hidden;
    }

    .carousel[aria-label="groups"] .carousel__track {
        margin-right: 80px;
    }

    .carousel__prev, .carousel__next {
        background-color: #36bcf9;
    }

    .carousel__prev--in-active, .carousel__next--in-active {
        display: none;
    }
</style>