<template>
    <div class="container my-8 sm:px-0 px-4">
        <div v-if="showContentInvite">
            <button class="btn bg-main-gray-light" @click="toggleMain()">
                <i class="fa-solid fa-arrow-left mr-2"></i> voltar para o grupo
            </button>
            <GroupInvite :group="group"/>
        </div>
        <div v-else>
            <!-- <HeaderPageGroup :group="group" @showNotifyModal="showNotifyModal" /> -->
            <GroupHeader :group="group" @parentToggleMain="toggleMain"/>
            <hr class="mt-6 mb-1">
            <Requiries v-if="requestList.length" :group="group" :requestList="requestList" @parentAddUserToGroup="addUserToGroup"/>      
            
            <GroupInfo :group="group"/>
            <div v-if="group.hasDraw && group.access" class="flex justify-between">
                <a class="mx-4 effect-hover" :href="baseUrl+'/grupo/'+group.slug+'/draw'">
                    <img class="rounded-md" :src="baseUrl+'/default/images/quem_me_tirou.png'">    
                </a>
                <a class="mx-4 effect-hover" :href="baseUrl+'/grupo/'+group.slug+'/drawn'">
                    <img class="rounded-md" :src="baseUrl+'/default/images/quem_eu_tirei.png'">    
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import ScrollReveal from 'scrollreveal';
    import GroupHeader from "@/components/pages/group/detail/GroupHeader";
    import GroupInfo from "@/components/pages/group/detail/GroupInfo";
    import GroupInvite from "@/components/pages/group/detail/GroupInvite";
    import Requiries from "@/components/pages/group/Requiries"
    

    export default {
        components: {
            ScrollReveal,
            GroupHeader,
            GroupInfo,
            GroupInvite,
            Requiries
        },
        props: ['datagroup'],
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                group: '',
                requestList: '',
                drawded: false,
                showNotify: false,
                dataNotify: {},
                showContentInvite: false,
                // error: null
            }
        },
        mounted() {
            this.group = JSON.parse(this.datagroup);
            this.getRequestGroup();
        },
        methods: {
            toggleMain() {
                this.showContentInvite = !this.showContentInvite;
            },
            getRequestGroup() {
                let slug = this.group.slug;
                let url = `${this.baseUrl}/api/v1/request/group/${slug}`;

                axios.get(url)
                    .then(response => {
                        this.requestList = response.data.length ? response.data : '';
                    })
                    .catch(errors => {
                        if(process.env.APP_DEBUG) console.log('error: ', errors);
                        this.showNotify = true;
                        this.dataNotify = errors;

                        setTimeout(()=>{
                             this.showNotify = false;
                        },4000);
                    })
            },
            showNotifyModal(notification) {
                this.showNotify = true;
                this.dataNotify = notification;
                setTimeout(()=>{
                     this.showNotify = false;
                },4000);
            },
            addUserToGroup(user) {
                this.group.users.push({
                    "name": user.userName,
                    "image": user.userImage,
                    "slug": user.userSlug
                });
                this.getRequestGroup()
            },
            load() {
                this.getRequestGroup(this.group.slug);
            }
        }
    }
</script>