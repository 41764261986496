<template>
    <div class="container">
        <div class="flex justify-center w-full mt-5">
            <div class="sm:card w-5/6 md:max-w-sm mb-10" :class="{'text-center' : loading}">
                <div v-if="loading" class="h-96 flex justify-center align-middle flex-col">
                    <pulse-loader :color="color" :size="size"></pulse-loader>
                </div>
                <template v-else>
                    <div class="text-center pt-4 pb-6">
                        <div class="bg-primary-dark rounded-full w-12 h-12 mx-auto my-0">
                            <i class="fa-solid fa-key text-white p-4 p-l1"></i>
                        </div>
                        <h3 class="mt-1">Esqueceu sua senha?</h3>
                        <p>Digite seu email cadastrado para receber as intruções de como redefinir sua senha.</p>
                    </div>
                    <div class="sm:card-body">
                        <form method="POST" action="" @submit.prevent="requestResetPassword($event)">
                            <input type="hidden" name="_token" :value="csrf_token">
                            <div class="form-field" :class="error ? 'error' : 'active'">
                                <label for="email">email</label>
                                <input v-model="email" id="email" class="input-form lowercase" type="email" name="email" required autofocus>
                                <small v-if="error" class="text-secondary-dark">{{error.message}}</small>
                            </div>
                            <div class="flex items-center justify-between">
                                <button class="btn btn-primary w-full" type="submit">
                                    enviar
                                </button>
                            </div>
                        </form>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import base from '@/base';
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
    export default {
        props: ['csrf_token'],
        components: {
            PulseLoader
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                email: '',
                error: '',
                loading: false,
                color: '#36BCF9',
                size: '15px',
            }
        },
        mounted() {
            base.labelAction();
        },
        methods: {

            requestResetPassword() {
                this.loading = true;
                this.error = '';
                let url = `${this.baseUrl}/api/password/email`;

                let config = {
                     method: 'post',
                     body: new URLSearchParams({
                        'token': this.csrf_token,
                         'email': this.email,
                     })
                }

                fetch(url, config)
                    .then(response => response.json())
                    .then(data => {
                        this.loading = false;
                        this.error = data.type == 'error' ? data : '';
                        Swal.fire({
                            icon: data.type,
                            text: data.message,
                            confirmButtonColor: '#36BCF9'
                        })
                    })

            },
        }
    }
</script>
