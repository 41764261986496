<template>
    <div class="text-sm">
        <p>Se seu amigo já tem um cadastro no <strong>meu amigo oculto</strong>, digite o nome ou email dele no campo abaixo e depois clique em <strong>convidar</strong>.</p>
        <p>Seu amigo será notificado assim que logar novamente em nosso site</p>
    </div>
    <div class="relative form-fields my-5 w-full">
        <span class="absolute inset-y-0 left-0 flex items-center pl-2">
            <i class="w-6 h-6 fa-solid fa-search mr-1 ml-1 mt-3 text-zinc-500"></i>
        </span>
        <input type="search" name="q" class="py-2 text-sm input-form pl-10" placeholder="Pesquise um amigo" autocomplete="off" v-model="termSearch" @keyup="termSearch.length > 2 ? searchUser() : ''">
        <button type="button" class="ml-2 btn btn-primary effect-hover" @click="searchUser()">procurar</button>
    </div>
    <div :class="listSearchUsers.length > 3 ? 'h-40 overflow-y-scroll' : ''" v-if="listSearchUsers.length > 0 && !loading">
        <div class="flex justify-between my-3" v-for="(user, index) in listSearchUsers" :key="index">
            <div class="flex">
            <div class="relative flex flex-shrink-0 items-end">
                    <img class="h-16 w-16 rounded-full" :src="user.image">    
                </div>
                <div class="flex flex-col justify-center mx-3">
                    <p class="text-sm bold text-primary-dark">{{user.name}}</p>
                    <p class="text-xs">{{user.email}}</p>
                </div>
            </div>

            <div class="flex items-center mr-3">
                <button v-if="!user.added && !user.isMember" class="btn bg-primary text-white" @click="inviteUser(user)">convidar</button>
                <span v-else-if="user.added && !user.isMember" class="text-green-500 bg-transparent text-sm"><i class="fa-solid fa-check"></i> convite enviado</span>
                <!-- Component starts here  -->
                <span v-if="user.isMember" class="group max-w-max relative mx-1 flex-col items-center justify-center p-1 text-gray-500 hover:text-gray-600 z-50">
                    <p class="text-sm text-center text-primary-dark"><i class="fa-solid fa-check-circle"></i> já faz parte do grupo</p>
                </span>
            </div>
        </div>
    </div>
    <div v-else-if="termSearch.length && listSearchUsers.length <= 0 && !loading" class="bg-slate-200 rounded-lg p-3">
        <p>Nenhum amigo encontrado.</p>
        <p class="py-2 text-orange-500 text-sm">
            <i class="fa-sharp fa-solid fa-circle-info"></i> <small>se seu amigo ainda não tiver cadastro, tente as opções: <strong>convide por email </strong> ou <strong>copie o link</strong>.</small>
        </p>
    </div>
    <div v-if="loading" class="text-center p-2">
        <pulse-loader :color="color" :size="size"></pulse-loader>
    </div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
export default {
    props: ['groupSlug'],
    components: {
        PulseLoader
    },
    data() {
        return {
            baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
            listSearchUsers: [],
            emails: [],
            emailsAdded: [],
            termSearch: '',
            loading: false,
            color: '#36BCF9',
            size: '15px',
        }
    },
    methods: {
            searchUser() {
                let term = this.termSearch;

                setTimeout(() => {
                    
                    if(this.termSearch && term == this.termSearch) {
                        this.loading = true;
                        let url = `${this.baseUrl}/api/v1/user/?search=${this.termSearch}&groupSlug=${this.groupSlug}`;
                        let formData = new FormData();
                        
                        formData.append('search', this.termSearch);
                        
                        let config = {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                                }
                        }
                        axios.get(url, formData, config)
                            .then(response => {
                                this.listSearchUsers = response.data;

                                if (this.listSearchUsers.length > 0) {
                                    this.listSearchUsers.forEach(element => {
                                            const found = this.emailsAdded.find((emailUser) => {
                                            return emailUser.slug == element.slug;
                                        });
                                        element.added = found ? true : false;
                                    });
                                }

                                this.loading = false;
                            })
                            .catch(errors => {
                                if(process.env.APP_DEBUG) console.log('error: ', errors);
                                this.loading = false;
                            })
                    }
                }, 300)
            },
            inviteUser(user) {

                // verifica qual index do array foi solicitado para enviar email
                const index = this.listSearchUsers.findIndex((el) => el.email === user.email);
                this.emailsAdded = [...this.emailsAdded, user];
                this.listSearchUsers[index].added = true;
                this.emails.push(user.email);

                if(this.emails.length) {
                    this.loadingSendInvite = true;
                    let url = `${this.baseUrl}/api/v1/group/invite/${this.groupSlug}`;
                    
                    let formData = new FormData();
                    formData.append('emails', this.emails);

                    let config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }

                    axios.post(url, formData, config)
                        .then(response => {
                            this.showNotify = true;
                            this.listNotify = response.data;
                            
                            let notification = {};
                            notification.type = "success";
                            notification.message = "Convite enviado aos amigos com sucesso.";
                            this.$emit('showNotifyModal', notification);

                            this.closeModal(response.data);
                        })
                        .then(()=>{
                            this.loadingSendInvite = false;
                            this.cleanFields();
                        })
                        .catch(errors => {
                            this.loadingSendInvite = false;
                            this.$emit('showNotifyModal', errors);
                        });
                    
                }
            },
    }
}
</script>