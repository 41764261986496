<template>

    <div class="container p-5">
        <div class="flex justify-between p- border-b-primary-dark border-b rounded-sm">
            <h3> Grupos que participo</h3>
            <a class="btn-link effect-hover" :href="baseUrl+'/grupo'">buscar novo grupo</a>
        </div>

        
        <div class="w-full my-5 mb-20">
            <MyGroups />
        </div>
        
        <div class="flex justify-between border-b-primary-dark border-b rounded-sm">
            <h3>Meus presentes</h3>
            <a class="btn-link" :href="baseUrl+'/presente'">adicionar presente</a>
        </div>
        <div class="w-full my-5 mb-20">
            <MyGifts/>
        </div>
    </div>
</template>

<script>
    import MyGroups from "@/components/includes/MyGroups";
    import MyGifts from "@/components/includes/MyGifts";

    export default {
        components: {
            MyGroups,
            MyGifts,
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
            }
        },
    }
</script>
