/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// INCLUDES COMPONENTS
import SweetAlert from './components/includes/SweetAlert';

// PRIVATE COMPONENTS

// COMMON COMPONENTS
import { createApp } from 'vue';
import Header from './components/common/Header.vue';
import Footer from './components/common/Footer.vue';

// PUBLIC PAGES
import Login from './pages/public/Login.vue';
import Register from './pages/public/Register.vue';
import Home from './pages/public/Home.vue';
import HomeMobile from './pages/public//HomeMobile.vue';

import PasswordResetRequest from './pages/public/password/RequestReset.vue';
import ResetPassword from './pages/public/password/ResetPassword.vue';

/**
 * PRIVATE PAGES
 */
import Profile from './pages/private/profile/Profile.vue';
import Dashboard from './pages/private/Dashboard';

// group
import Group from './pages/private/group/Group';
import Detail from './pages/private/group/detail/Detail';

// gift
import Gift from './pages/private/gift/Gift.vue';


/**
 * ADM PAGES
 */

import Admin from './pages/Admin/Admin.vue';

// old --- old --- old --- old --- old --- old --- old --- old --- old --- old --- 


import Edit from './pages/private/group/detail/Edit.vue';

import UserDraws from './components/pages/group/userDraws/Index.vue';
import DrawnByUser from './components/pages/group/drawnByUser/Index.vue';

import NotifyMessage from './components/utils/notify/NotifyMessage.vue';

let app=createApp({})

/**
 * INCLUDES
 */
app.component('header-component', Header);
app.component('footer-component', Footer);
app.component('sweetalert-component', SweetAlert);

/**
 * AUTHENTICATION
 */
app.component('login-page', Login);
app.component('register-page', Register);

app.component('password-request-component', PasswordResetRequest);
app.component('password-reset-component', ResetPassword);

/**
 * 
 */

/**
 * PAGES
 */
// DESK
// PRIVATE
app.component('profile-page', Profile);
app.component('dashboard-page', Dashboard);
app.component('group-page', Group);
app.component('group-detail-page', Detail);

app.component('home-component', Home);
app.component('gift-index-component', Gift);

app.component('group-edit-page', Edit);

app.component('draw-index-component', UserDraws);
app.component('drawn-index-component', DrawnByUser);

// MOBILE
app.component('home-mobile-component', HomeMobile);


// ADMIN
app.component('admin-page', Admin);

/**
 * UTILS
 */
app.component('notify-component', NotifyMessage);


app.mount("#app")



/*
Vue.component('ds-card', require('./components/pages/dev/design-system/drawer-content/actions/card/Index.vue').default);
Vue.component('ds-button', require('./components/pages/dev/design-system/drawer-content/actions/button/Index.vue').default);
Vue.component('ds-dropdown', require('./components/pages/dev/design-system/drawer-content/actions/dropdown/Index.vue').default);
Vue.component('ds-color', require('./components/pages/dev/design-system/drawer-content/actions/color/Index.vue').default);

Vue.component('tailwind-component', require('./components/pages/tailwind/Index.vue').default);
*/
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */




