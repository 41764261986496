<template>
    <div class="content-center my-10">
        <div class="flex justify-center">
            <span class="logo flex justify-center">
                <a href="/">
                    <img class="w-40 md:w-125px" :src="baseUrl+'/default/images/logo_meu_amigo_oculto_circle.png'">
                </a>
            </span>
        </div>
        <div class="flex justify-center w-full mt-5">
            <div class="sm:card w-5/6 md:max-w-sm mb-10">
                <div class="text-center pt-4">
                    <h3>Entrar</h3>
                </div>
                <div class="sm:card-body">
                    <form method="POST" action="" @submit.prevent="login($event)">
                        <input type="hidden" name="_token" :value="csrf_token">
                        <div class="form-field">
                            <label for="email">email</label>
                            <input v-model="email" id="email" class="input-form lowercase" type="email" name="email" required autofocus>
                        </div>
                        <div class="form-field">
                            <div class="icon-input">
                                <label for="email">senha</label>
                                <input v-model="password" id="password" class="input-form" :type="showPass ? 'text' : 'password'" name="password" required autocomplete="current-password">
                                <a tabindex="-1" href="#" @click="toggleTypePass()">
                                    <i class="fa-solid" :class="showPass ? 'fa-eye-slash' : 'fa-eye'"></i>
                                </a>
                            </div>
                        </div>
                        <div class="my-6">
                            <a class="btn-link" href="/password/reset">esqueci minha senha</a>
                        </div>
                        <div v-if="loading" class="text-center p-2">
                            <pulse-loader :color="color" :size="size"></pulse-loader>
                        </div>
                        <div v-else class="flex items-center justify-between">
                            <button class="btn btn-primary w-full" type="submit">
                                entrar
                            </button>
                        </div>
                    </form>
                </div>
                <div class="sm:card-footer flex justify-center py-10">
                    <span class="mt-5">Não possui uma conta? <a class="btn-link" href="/register">Criar conta</a></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import base from '@/base';
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
    import Swal from 'sweetalert2';
    export default {
        props: ['csrf_token'],
        components: {
            PulseLoader
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                email: '',
                password: '',
                showPass: false,
                loading: false,
                color: '#36BCF9',
                size: '15px',
                dataNotify: {},
            }
        },
        mounted() {
            base.labelAction();
        },
        methods: {

            login(event) {
                
                this.loading = true;

                let url = `${this.baseUrl}/api/login`;
                let config = {
                     method: 'post',
                     body: new URLSearchParams({
                         'email': this.email,
                         'password': this.password
                     })
                }

                fetch(url, config)
                    .then(response => response.json())
                    .then(data => {
                        if(data.token) {
                            document.cookie = 'token='+data.token+';SameSite=Lax';
                            document.cookie = 'maoaccess='+this.email;
                        }
                        if(data.error) {
                            this.loading = false;

                            const Toast = Swal.mixin({
                                toast: true,
                                position: 'top',
                                showConfirmButton: false,
                                timer: 4500,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'error',
                                title: 'Ops! Algo deu errado.',
                                html: data.error

                            })
                        } else {
                            event.target.submit();
                        }
                        this.cleanFields();
                        
                    })
                    .catch(error => {

                        console.error(error);
                        const Toast = Swal.mixin({
                                toast: true,
                                position: 'top',
                                showConfirmButton: false,
                                timer: 4500,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'error',
                                title: 'Ops! Algo deu errado.',
                                text: 'Tente novamente em alguns instantes'
                            })
                    });

            },
            cleanFields() {
                this.email = '';
                this.password = '';
            },
            toggleTypePass() {
                this.showPass = !this.showPass;
            }
        }
    }
</script>
