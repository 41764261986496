<template>
    <div v-if="alert" class="container text-center mt-4 notify" :class="alert.type" role="alert">
        <span>{{alert.message}}</span>
    </div>
</template>

<script>
    import ScrollReveal from 'scrollreveal';
    export default {
        props: ['alert'],
        mounted() {
            ScrollReveal().reveal('.notify');
        },
    }
</script>
    