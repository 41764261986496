<template>
    <div class="transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0 overflow-y-scroll md:py-12" id="modal">
        <div role="alert" class="container mx-auto md:w-11/12 max-w-lg">
            <div class="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border h-vh md:h-auto">
                <button class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600" aria-label="close modal" role="button" @click="closeModal()">
                    <svg  xmlns="http://www.w3.org/2000/svg"  class="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                </button>

                <div class="w-full flex justify-start text-primary-dark mb-3">
                    <i class="fa-solid fa-people-group fa-2x"></i>
                </div>

                <h3>Criar novo grupo</h3>
                
                <div class="form-image" :class="{'border-secondary': dataError.image}" :style="'background-image: url('+imagePreview+')'">
                    <label>
                        <template v-if="imagePreview">
                            <button class="hover:text-primary-dark font-semibold" v-if="imagePreview" @click="imagePreview = ''"> <i class="fa-solid fa-trash mr-2"></i> remover</button>
                        </template>
                        <template v-else>
                            <div class="hover:text-primary-dark font-semibold flex flex-col justify-center h-full">
                                <i class="fa-solid fa-camera"></i>
                                <span>Selecione uma imagem (jpg ou png)</span>
                                <input v-if="!imagePreview" type="file" accept=".jpg, .jpeg, .png" class="" id="groupImage" aria-describedby="groupImage" placeholder="Selecione uma imagem" :value="imagePreview" @change="loadImagem($event)">
                                <small v-if="dataError.image" class="text-secondary-dark float-left">
                                    <ul>
                                        <li v-for="(msg, index) in dataError.image" :key="index">
                                            {{msg}}
                                        </li>
                                    </ul>
                                </small>
                            </div>
                        </template>
                    </label>
                </div>
                <div class="form-field" :class="dataError.name ? 'error' : 'active'">
                    <label for="name">Nome do grupo</label>
                    <input type="text" maxlength="50" class="input-form" id="groupName" aria-describedby="groupName" v-model="group.name">
                    <small class="text-xs text-gray-400">restam {{ 50 - group.name.length }} caracteres</small>
                    <small v-if="dataError.name" class="text-secondary-dark">
                        <ul>
                            <li v-for="(msg, index) in dataError.name" :key="index">
                                {{msg}}
                            </li>
                        </ul>
                    </small>
                </div>
                <div class="form-field">
                    <label for="descricao">Descrição do grupo</label>
                    <textarea class="input-form resize-none" id="groupDescription" aria-describedby="groupDescription" v-model="group.description" maxlength="100"></textarea>
                    <small class="text-xs text-gray-400">restam {{ 100 - group.description.length }} caracteres</small>
                </div>
                <div class="form-field active">
                    <label for="data">Data do evento</label>
                    <input type="date" class="input-form" id="groupEventDate" :min="today" aria-describedby="groupEventDate" aria-placeholder="Data" v-model="group.eventDate">
                    <small class="hidden"></small>
                </div>
                <div v-if="loading" class="text-center p-2">
                    <pulse-loader :color="color" :size="size"></pulse-loader>
                </div>
                <div v-else class="flex justify-end">
                    <button class="effect-hover btn border-t-main-gray mr-3" @click="closeModal()">cancelar</button>
                    <button class="effect-hover btn btn-primary" @click="addGroup()">criar grupo</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import base from '@/base';
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

    export default {
        components: {
            PulseLoader
        },
        data() {
            return {
                baseUrl: `${process.env.MIX_APP_URL}`,
                baseUrlMain: process.env.MIX_APP_URL,
                group: {
                    name: '',
                    image: '',
                    description: '',
                    eventDate: ''
                },
                imagePreview: '',
                btnClicked: false,
                today: new Date().toLocaleDateString('sv'),
                dataError: '',
                loading: false,
                color: '#36BCF9',
                size: '15px',
            }
        },
        mounted() {
            base.labelAction();
        },
        methods: {
            clearForm() {
                this.group.image = '';
                this.imagePreview = '';
                this.group.name = '';
                this.group.description = '';
                this.group.eventDate = '';
            },
            addGroup() {

                let url = `${this.baseUrl}/api/v1/group`;
                let formData = new FormData();
                formData.append('name', this.group.name);
                formData.append('image', this.group.image[0]);
                formData.append('description', this.group.description);
                formData.append('event_date', this.group.eventDate);

                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                let self = this;
                axios.post(url, formData, config)
                    .then(response => {
                        
                        this.clearForm();
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top',
                            showConfirmButton: false,
                            timer: 3500,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })
                        Toast.fire({
                            icon: response.data.type,
                            title: response.data.message,
                            html: 'Você será redirecionado para a página do seu grupo em breve'
                        });

                        setTimeout(()=>{
                            window.location.href = `${this.baseUrl}/grupo/${response.data.info.groupslug}`; 
                        },2600);
                    })
                    .catch(function(error) {
                        self.dataError = error.response.data.message;
                        if(self.dataError.image) self.imagePreview = '';
                    })
            },
            loadImagem(e) {
                if(this.imagePreview) this.imagePreview = '';
                if(e.target.files.length > 0){
                    this.imagePreview = URL.createObjectURL(e.target.files[0]);
                    this.group.image = e.target.files;
                }
            },
            closeModal(value) {
                this.$emit('update', value);
            }
        }
    }
</script>
