<template>
    <div class="card relative m-0 p-0">
        <button class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-white hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600" aria-label="close modal" role="button" @click="closeModal()">
            <i class="fa-solid fa-close mr-2"></i>
        </button>

        <div class="card-header pb-0 bg-primary rounded-t">
            <img class="m-auto my-2 w-20" :src="baseUrl+'/default/images/others/boletim-de-noticias.png'" alt="notificacao">
        </div>
        
        <notify-component v-if="showNotify" :alert="dataNotify"></notify-component>

        <div class="card-body p-5">
            <h2>Convites de grupo</h2>
            <p>Aqui você vê os convites que seus amigos te enviaram</p>

            <ul v-if="invites.length > 0" class="divide-y divide-gray-200"  :class="invites.length > 3 ? 'overflow-y-scroll h-52' : ''">
                <li class="py-3 sm:py-4" v-for="(invite, index) in invites" :key="index" :class="index === invites.length && index != 1? 'border-b border-b-slate-500 pb-2' : ''">
                    <div class="flex items-center space-x-4">
                        <div class="flex-shrink-0">
                            <i class="fa-solid fa-people-group"></i>
                            <!-- <img class="w-8 h-8 rounded-full" src="https://flowbite.com/docs/images/people/profile-picture-1.jpg" alt="Neil image"> -->
                        </div>
                        <div class="flex-1 min-w-0">
                            <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                Nome do grupo:
                            </p>
                            <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                                <a :href="baseUrl +'/grupo/'+invite.groupSlug">
                                    {{invite.groupName}}
                                </a>
                            </p>
                        </div>
                        <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white" v-if="!invite.response">
                            <button @click="acceptInvite(invite)" class="btn bg-primary mr-2">aceitar</button>
                            <button @click="refuseInvite(invite)" class="btn bg-slate-300">recusar</button>
                        </div>
                        <div v-else-if="invite.response == 'accepted'">
                            <p>Convite aceito <i class="fa-solid fa-check-circle text-primary mr-2"></i></p>
                        </div>
                        <div v-else-if="invite.response == 'refused'">
                            <p>Convite recusado <i class="fa-solid fa-circle-xmark text-secondary-dark mr-2"></i></p>
                        </div>
                    </div>
                </li>
            </ul>

            <p v-else class="mt-2 text-center"> Você não tem convites no momento.</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            invites: {
                default: [{}]
            }
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                showNotify: false,
            }
        },
        mounted() {
            console.log(this.$props.invites);
        },
        methods: {
            acceptInvite(groupInvite) {
                let url = `${this.baseUrl}/api/v1/user/invite/accept/${groupInvite.groupSlug}`;

                axios.post(url)
                    .then(response => {
                        groupInvite.response = 'accepted';
                        console.log('response invite/accept');
                        this.$emit('callBackParentInvite');
                    })
                    .catch(errors => {
                        if(process.env.APP_DEBUG) console.log(errors);
                        this.showNotify = true;
                        this.notify = errors;

                        setTimeout(()=>{
                             this.showNotify = false;
                        },4000);
                    });
            },
            refuseInvite(groupInvite) {
                let url = `${this.baseUrl}/api/v1/user/invite/refuse/${groupInvite.groupSlug}`;

               axios.post(url)
                   .then(response => {
                        groupInvite.response = 'refused';
                        this.$emit('callBackParentInvite');
                   })
                   .catch(errors => {
                        if(process.env.APP_DEBUG) console.log(errors);
                        this.showNotify = true;
                        this.notify = errors;

                        setTimeout(()=>{
                             this.showNotify = false;
                        },4000);
                   });
            },
            closeModal() {
                this.$emit('parentCloseModalInvites');
            }
        }
    }
</script>