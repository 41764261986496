<template>
    <div class="container my-8 sm:px-0 px-4">
        <div class="flex justify-between mt-4">
            <div class="sm:px-0 px-4 text-primary">
                <i class="fa-solid fa-people-group"></i> <a class="btn-link" :href="baseUrl+'/perfil?tab=1'">ver grupos que participo</a>
            </div>
            <div class="flex justify-between">
                <button v-if="qtyInvites" @click="openModalInvites()" class="notify-bells flex text-orange-400 sm:mr-8">
                    <div id="bellNotification" class="bell-icon" tabindex="0">
                        <svg class="" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="30px" viewBox="0 0 50 30" enable-background="new 0 0 50 30" xml:space="preserve">
                            <g class="bell-icon__group">
                                <path class="bell-icon__ball" id="ball" fill-rule="evenodd" stroke-width="1.5" clip-rule="evenodd" fill="none" stroke="#currentColor" stroke-miterlimit="10" d="M28.7,25 c0,1.9-1.7,3.5-3.7,3.5s-3.7-1.6-3.7-3.5s1.7-3.5,3.7-3.5S28.7,23,28.7,25z"/>
                                <path class="bell-icon__shell" id="shell" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#currentColor" stroke-width="2" stroke-miterlimit="10" d="M35.9,21.8c-1.2-0.7-4.1-3-3.4-8.7c0.1-1,0.1-2.1,0-3.1h0c-0.3-4.1-3.9-7.2-8.1-6.9c-3.7,0.3-6.6,3.2-6.9,6.9h0 c-0.1,1-0.1,2.1,0,3.1c0.6,5.7-2.2,8-3.4,8.7c-0.4,0.2-0.6,0.6-0.6,1v1.8c0,0.2,0.2,0.4,0.4,0.4h22.2c0.2,0,0.4-0.2,0.4-0.4v-1.8 C36.5,22.4,36.3,22,35.9,21.8L35.9,21.8z"/>
                            </g>
                        </svg>
                    </div>
                    <span ref="textElement" class="text-info-invite hidden sm:flex align-middle">você tem {{ qtyInvites }} {{ qtyInvites > 1 ? 'convites' : 'convite' }}</span>
                    <span class="tip-invite">{{ qtyInvites }}</span>
                </button>
                <button @click="openModal = !openModal" class="fixed bottom-24 right-3 effect-hover btn btn-primary sm:static scroll-button z-40" :class="{'scroll-effect': scrollDown}"><i class="fa-regular fa-pen-to-square"></i> <span class="scroll-efect-text" :class="{'hidden-item': scrollDown}">criar grupo</span></button>
            </div>
        </div>

        <!-- SEARCH GROUP COMPONENT -->
        <div v-if="showGroupComponent" class="pt-5 md:pt-10">
            <Search @parentSetInvite="setInvite" />
        </div>
    </div>

    <transition name="fade">
        <div v-if="openModal" class="mobile-modal-content bg-opacity-75 h-vh">
            <Create :openModal="openModal" @update="closeModalCreateGroup" />
        </div>
    </transition>

     <transition name="fade">
        <div v-if="invites.length && showGroupInvitesModal" class="mobile-modal-content bg-opacity-75 h-vh">
            <InvitationReceived :invites="invites" @parentCloseModalInvites="closeModalInvites" @callBackParentInvite="responseParentInvite" />
        </div>
    </transition>

</template>

<script>
    import MyGroups from "@/components/includes/MyGroups";
    import Search from "@/components/pages/group/Search";
    import Create from "@/components/pages/group/Create";
    import ScrollReveal from 'scrollreveal';
    import InvitationReceived from "@/components/pages/group/InvitationReceived";

    export default {
        props: ['groups'],
        components: {
            MyGroups,
            Search,
            Create,
            InvitationReceived,
            ScrollReveal
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                layout: 'search',
                openModal: false,
                tabItem: 'my-groups',
                groupList: '',
                userAuth: '',
                showNotify: false,
                dataNotify: {},
                showGroupInvitesModal: false,
                scrollDown: false,
                configGroup: {
                    type: 'sm',
                    showUsers: false
                },
                inviteGroup: '',
                qtyInvites: 0,
                invites: [],
                showGroupComponent: true,
            }
        },
        mounted() {
            ScrollReveal().reveal('.container');
            this.getInvites();
            setTimeout(()=>{
                if(window.scrollY && window.innerWidth <= 640) this.scrollDown = true;  
            },500)
          window.addEventListener('scroll', this.handleScroll);
        },
        beforeDestroy() {
          window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            setInvite(invite) {
                this.closeModalInvites();
                this.invites = invite;
                this.getInvites(false);
            },
            handleScroll() {
                const currentScrollPosition = window.scrollY || document.documentElement.scrollTop;
                if (currentScrollPosition > this.previousScrollPosition) {
                    this.scrollDown = true;
                } else {
                    this.scrollDown = false;
                }
                
                this.previousScrollPosition = currentScrollPosition;
            },

            closeModalCreateGroup(data = null) {
                this.openModal = false;
            },
            openModalInvites() {
                this.showGroupInvitesModal = true;
                this.getInvites();
            },
            closeModalInvites() {
                this.showGroupInvitesModal = false;
            },
            responseParentInvite() {
                this.showGroupComponent = false;
                this.getInvites();
            },
            getInvites(getAllInvites = true) {
                this.showGroupComponent = false;
                if(getAllInvites) {
                    let url = `${this.baseUrl}/api/v1/user/invite`;
                    axios.get(url)
                        .then(response => {
                            this.invites = response.data;
                            this.qtyInvites = this.invites.length;
                            this.showGroupInvitesModal = true;
                            this.showGroupComponent = true;
                        })
                        .catch(errors => {
                            if(process.env.APP_DEBUG) console.log('error: ', errors);
                        })
                } else {
                    this.showGroupInvitesModal = true;
                    this.showGroupComponent = true;
                }

            },
        }
    }
</script>
