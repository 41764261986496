<template>
    <div class="content">
            <h3>Selecione o(s) grupo(s) para associar este presente</h3>
            <VueMultiselect v-model="selected" :multiple="true" :options="options" :selectedLabel="selectedLabel" :deselectLabel="deselectLabel" :selectLabel="selectLabel" placeholder="Selecione um ou mais grupos"></VueMultiselect>
            <small class="text-orange-400">O presente deve ser associado, no mínimo, a um grupo.</small>
            <div class="py-10" v-if="selected.length">
                <h3>Selecione como deseja cadastrar seu presente</h3>
                <Tabs :tabs="configTabs" :firstTabActive="false" :hideTabText="false">
                    <template v-slot:slot-search>
                        <div class="grow w-full lg:w-initial">
                            <p><small>A pesquisa é feita utilizando o serviço do <strong>Mercado Livre</strong>.</small></p>
                            <div class="relative form-fields py-1">
                                <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                                    <svg fill="none" stroke="#a4a4a4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                </span>
                                <input type="search" name="q" class="py-2 text-sm input-form pl-10" placeholder="Digite o nome do produto, marca ou termo" autocomplete="off" v-model="filterTerm" v-on:keyup.enter="findItem()">
                                <button type="button" class="ml-2 btn btn-primary effect-hover" @click="findItem()">procurar</button>
                            </div>
                            <p>
                                <small class="text-orange-400">Os dados exibidos são fornecidos pelo serviço do Mercado Livre e são de responsabilidade da plataforma</small>
                            </p>
                        </div>
                        <div class="my-5" v-bind:class="{ 'invisible': load }" v-if="!addGiftManually && selected.length">
                            <div v-if="loadingMeliList" class="text-center p-2 mt-5">
                                <pulse-loader :color="color" :size="size"></pulse-loader>
                            </div>
                            <div class="grid grid-cols-2 sm:grid-cols-5 place-content-betweeen" v-if="meliItems.length && !loadingMeliList"> 
                                <div class="widget card m-2 rounded-lg border border-gray-200 shadow-md" v-for="(item, index) in meliItems" :key="index">
                                    
                                    <div class="card-body text-xs md:text-sm h-72">
                                        <div class="w-full">
                                            <img class="m-auto w-40" :src="item.thumbnail" alt="">
                                        </div>
                                        <div class="text-center mb-3">{{item.title}}</div>
                                        <div class="text-center text-primary-dark">R${{item.price}}</div>
                                    </div>
                                    <div class="card-footer">
                                        <button class="effect-hover btn btn-primary w-full" @click="addGift(index)">adicionar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!meliItems.length && searched" class="py-4 flex items-center rounded-lg">
                            <img class="w-8 mr-5" :src="baseUrl+'/default/images/others/empty.png'" alt="">
                            <p>Não encontrados seu presente. <br> Tente Realizar uma nova busca</p>
                        </div>
                    </template>
                    <template v-slot:slot-manually>
                        <button class="flex items-center flex-col effect-hover cursor-pointer group lock rounded-lg p-4 bg-whitering-slate-900/5 card space-y-3 hover:bg-primary hover:ring-sky-500 w-full" @click="setGiftManually()">
                            <i class="fa-solid fa-gift text-primary group-hover:text-white"></i> <strong class="text-slate-900 group-hover:text-white text-sm font-semibold">Clique para começar a adicionar as seguintes informações do presente manualmente:</strong>
                            <p class="text-slate-500 group-hover:text-white text-sm">nome, site, valor e alguma descrição</p>
                        </button>
                    </template>
                </Tabs>
            </div>
            
            <div v-if="showNotifyItem" class="float-notity">
                <notify-component v-if="showNotifyItem" :alert="dataNotify"></notify-component>
            </div>
        </div>
</template>

<script>  
    import base from '@/base';
    import VueMultiselect from 'vue-multiselect'
    import ScrollReveal from 'scrollreveal';
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
    import Swal from 'sweetalert2';
    import 'animate.css';

    import Tabs from '@/components/includes/Tabs';

    export default {
        props: ['group_collection'],
        components: {
            Tabs,
            VueMultiselect,
            PulseLoader
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                mlApi: 'https://api.mercadolibre.com/sites/MLB/search',
                color: '#36BCF9',
                size: '15px',
                gift: {
                    name: '',
                    url: '',
                    price: '',
                    info: '',
                    image: '',
                },
                groups: [],
                selectedGroups: [],
                filterTerm: '',
                meliItems: [],
                load: false,
                grid: false,
                addGiftManually: false,
                giftManuallyValues: [],
                manually: true,
                selected: '',
                options: [],
                selectLabel: 'clique para selecionar',
                deselectLabel: 'clique para remover a seleção',
                selectedLabel: 'selecionado',
                btnClicked: false,
                dataNotify: {},
                showNotifyItem: false,
                loadingMeliList: false,
                searched: false,
                configTabs: [
                    {
                        title: 'Pesquisar Produto',
                        iconClass: 'fa-magnifying-glass',
                        slotName: 'slot-search'
                    },
                    {
                        title: 'Cadastrar Manualmente',
                        iconClass: 'fa-list-check',
                        slotName: 'slot-manually'
                    },
                ]
            }
        },
        mounted() {
            this.baseActions();
            this.groups = JSON.parse(this.group_collection);
            this.options = this.groups.filter(item => item.name).map(o => o.name);
            ScrollReveal().reveal('.container');
            ScrollReveal().reveal('.modal-content');
        },
        unmounted() {
            this.clearForm();
        },
        methods: {
            baseActions() {
                base.labelAction();
            },
            findItem() {
                this.setGiftManually(false);
                this.loadingMeliList = true;

                axios.get(`${this.mlApi}?q=${this.filterTerm}`)
                .finally(()=>{ this.loadingMeliList = false; })
                .then(resp => {
                    this.meliItems = resp.data.results;
                    this.searched = true;
                    setTimeout(() => {
                        this.revealAction('.widget');
                    },1000)
                });
            },
            revealAction(item) {
                ScrollReveal().reveal(item, { interval: 100, delay: 1, duration: 500, reset: true });
            },
            setGroup() {
                if (this.selected) {
                    this.selectedGroups = [];
                    this.selected.forEach(item => {
                        let index = this.groups.findIndex((element) => element.name === item);
                        this.selectedGroups.push(this.groups[index].slug);
                    });
                }
            },
            clearForm() {
                    this.gift.name = '';
                    this.gift.image = '';
                    this.gift.info = '';
                    this.gift.url = '';
                    this.gift.price = '';
            },
            addGift(index = null) {

                if(this.validateValues() && this.addGiftManually == 'manually') {
                    this.btnClicked = true;
                    return;
                }

                let url = `${this.baseUrl}/api/v1/gift`
                this.setGroup(); 
                let formData = new FormData();
                formData.append('name', index == null ? this.giftManuallyValues[1] : this.meliItems[index].title);
                formData.append('url', index == null ? this.giftManuallyValues[2] : this.meliItems[index].permalink);
                formData.append('price', index == null ? this.giftManuallyValues[3] : this.meliItems[index].price);
                formData.append('info', index == null ? this.giftManuallyValues[4] : '');
                formData.append('image', index == null ? '' : this.meliItems[index].thumbnail);
                formData.append('groups_slug', this.selectedGroups);
                formData.append('is_api', index == null ? 0 : 1);
                
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                let toastWidth = window.innerWidth <= 640 ? '100%' : '420px';
                
                axios.post(url, formData, config)
                    .then(response => {
                        this.clearForm();
                        const Toast = Swal.mixin({
                        toast: true,
                        position: 'top',
                        showConfirmButton: false,
                        timer: 2000,
                        width: toastWidth,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'success',
                            title: 'Lista de presentes criada com sucesso!',
                            text: 'clique em "meus presentes" para ver suas listas'
                        })
                    })
                    .catch(errors => {
                        this.showNotifyItem = true;
                        this.dataNotify = errors;

                        setTimeout(()=>{
                             this.showNotifyItem = false;
                        },4500);
                    })
            },
            validateValues() {
                if (this.gift.name) {
                    return false;
                }
                return true;
            },
            loadImagem(e) {
                this.gift.image = e.target.files;
            },
            setGiftManually(type = true) {
                this.addGiftManually = type;
                if (this.addGiftManually) {
                    this.meliItems = [];
                    this.modalGiftManualy();
                }
                setTimeout(()=>{
                    this.baseActions();
                },1)
            },
            async modalGiftManualy() {
                const steps = ['1', '2', '3', '4']
                const titles = {
                    '1': 'Nome do produto*',
                    '2': 'Site',
                    '3': 'Valor',
                    '4': 'Descrição'
                };

                const inputTypes = {
                    '1': 'text',
                    '2': 'text',
                    '3': 'text',
                    '4': 'textarea'
                };
                
                const inputTexts = {
                    '1': 'insira um nome para o presente que quer ganhar',
                    '2': 'em qual site seu amigo encontra o produto?',
                    '3': 'valor aproximado para este produto (somente números e vírgula para separar os centavos)',
                    '4': 'quer inserir uma descrição para ajudar seu amigo a encontrar esse presente?'
                };
                
                const placeholders = {
                    '1': 'nome do produto que deseja ganhar',
                    '2': 'exemplo: http://site.com.br',
                    '3': 'R$ 50,00',
                    '4': '',
                };

                const validationMessages = {
                    '1': 'Por favor, digite um nome para o presente',
                    '2': 'Insira um site seguindo esse exemplo: http://site.com.br'
                };

                let attributes = {
                    confirmButtonText: 'próximo',
                    confirmButtonColor: '#36BCF9',
                    cancelButtonColor: '#BDBDBD',
                    progressSteps: steps,
                    inputAttributes: {
                        required: false,
                        maxlength: 250
                    },
                    reverseButtons: true,
                };

                let swalQueueStep = Swal.mixin(attributes)

                this.giftManuallyValues = [];
                let currentStep;

                for (currentStep = 1; currentStep <= steps.length;) {
                    if(currentStep > 1) {
                        delete attributes.inputAttributes.required;
                    };

                    const result = await swalQueueStep.fire({
                        title: titles[currentStep],
                        text: inputTexts[currentStep],
                        input: inputTypes[currentStep],
                        inputValue: this.giftManuallyValues[currentStep] ?? '',
                        validationMessage: validationMessages[currentStep],
                        inputPlaceholder: placeholders[currentStep],
                        currentProgressStep: currentStep - 1,
                        showCancelButton: true,
                        cancelButtonText: currentStep > 1 ? 'voltar' : 'cancelar',
                        allowOutsideClick: false,
                        showCloseButton: true,
                    })
                    
                    if((result.dismiss && currentStep == 1) || result.dismiss == 'close') break;

                    if (result.dismiss === Swal.DismissReason.cancel) {
                        currentStep--;
                    } else if (result.value || currentStep > 1) {
                        this.giftManuallyValues[currentStep] = result.value ? result.value : '';
                        currentStep++;
                    }
                }
                
                if (currentStep > steps.length) {
                    this.addGift();
                }
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
    .clip-bottom {
        clip-path: polygon(100% 50%, 0 0, 100% 0, 50% 100%, 0 0)
    }
</style>