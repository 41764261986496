<template>
    <div class="tags-input">
      <template v-for="(email, index) in emailsWrote" :key="index" >
        <span class="tags-input-tag px-2 py-1 rounded-sm" :class="email.valid? 'bg-opacity-60 bg-green-300 opacity-80': 'tag-invalid bg-red-200'">
          <span class="valid-email" v-if="email.valid">{{ email.adress }}</span>
          <span class="invalid-email " v-else>{{ email.adress }}</span>
          <button type="button" class="tags-input-remove" @click="removeTag(email)">
            &times;
          </button>
        </span>
    </template>
      <input
        class="tags-input-text"
        placeholder="digite o email ..."
        @input="handleSearch"
        @keydown.enter.prevent="addTag"
        @keydown.tab.prevent="getValue"
        @change.space.prevent="getValue"
        @blur="getValue"
        @paste="getValue"
        v-model="newEmailTag.adress"
      />
    </div>
  </template>
  <script>
    export default {
      name: "TagInput",
      props: ['emailsWrote'],
      data() {
          return {
              newEmailTag: {
                adress: ''
              },
              typingTimer: null,
              doneTypingInterval: 3000, // 3 segundos
          };
      },
  
      methods: {
        handleSearch() {
          clearTimeout(this.typingTimer);
          this.typingTimer = setTimeout(this.addTag, this.doneTypingInterval);
        },
        addTag() {
            this.newEmailTag.adress = this.newEmailTag.adress.replace(/ /g,'');
            if (this.newEmailTag.adress.trim().length === 0 || this.emailsWrote.includes(this.newEmailTag.adress.trim())
            ) {
                return; // colocar aletar de que ja foi inserido
            }
  
            let user = this.newEmailTag.adress.substring(0, this.newEmailTag.adress.indexOf("@"));
            let domain = this.newEmailTag.adress.substring(this.newEmailTag.adress.indexOf("@")+ 1, this.newEmailTag.adress.length);
  
            if ((user.length >=1) &&
                (domain.length >=3) &&
                (user.search("@")==-1) &&
                (domain.search("@")==-1) &&
                (user.search(" ")==-1) &&
                (domain.search(" ")==-1) &&
                (domain.search(".")!=-1) &&
                (domain.indexOf(".") >=1)&&
                (domain.lastIndexOf(".") < domain.length - 1)) {
              this.newEmailTag.valid = true;
            }
            else{
              this.newEmailTag.valid = false;
            }
  
            this.newEmailTag.adress.trim();
  
            this.$emit("addEmailToINvite", [...this.emailsWrote, this.newEmailTag]);
            this.newEmailTag = {};
        },
        getValue(event){
          setTimeout(() => {
              this.newEmailTag.adress = event.target.value;
              this.addTag();
            }, 100);
        },
        removeTag(email) {
            this.$emit("addEmailToINvite", this.emailsWrote.filter((t) => t !== email));
        },
      },
    };
  </script>
  
  <style scoped>
  .tags-input {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    border-width: 1px;
    border-radius: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.1rem;
  }
  
  .tags-input-tag {
    display: inline-flex;
    line-height: 1;
    align-items: center;
    font-size: 0.875rem;
    margin-right: 0.5rem;
    margin-bottom: 0.35rem;
  }
  
  .tags-input-tag:last-of-type {
    margin-right: 0;
  }
  
  .tags-input-remove {
    font-size: 1.125rem;
    line-height: 1;
  }
  
  .tags-input-remove:first-child {
    margin-right: 0.25rem;
  }
  
  .tags-input-remove:last-child {
    margin-left: 0.25rem;
  }
  
  .tags-input-remove:focus {
    outline: 0;
  }
  
  .tags-input-text {
    flex: 1;
    outline: 0;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-left: 0.5rem;
    margin-bottom: 0.25rem;
    min-width: 10rem;
  }
  
  </style>