<template>
    <Carousel v-if="gifts.length" :mouseDrag="true" :breakpoints="breakpointsGifts" :aria-label="'gifts'">
        <Slide v-for="(gift, index) in gifts" :key="index">
            <div class="carousel__item rounded-xl shadow-xl">
                <div v-if="gift.isOwner" class="float-right mt-2 cursor-pointer absolute right-0">
                    <li>
                        <button @click="preareRemoveGift(gift)" href="#" class="effect-hover flex text-secondary-dark items-center px-2 py-1 transition">
                            <i class="fa-solid fa-trash-alt mr-1"></i>
                        </button>
                    </li>
                </div>
                <img class="m-auto w-28 my-2" :src="gift.image" alt="">
                <p class="text-sm font-semibold text-gray-500 h-16 px-3">
                    <ul>
                        <li><strong>{{gift.name.substring(0, 20)}}</strong></li>
                        <li v-if="gift.info">{{gift.info.substring(0, 75)}}</li>
                    </ul>
                </p>
                <div class="mt-4 mb-2 flex items-center justify-between h-10" :class="gift.is_api === 1 ? 'justify-between' : 'justify-center'">
                    <p class="block text-sm font-semibold text-primary-dark cursor-auto pl-4" v-if="gift.price">R$ {{gift.price}}</p>
                    <button @click="setDetailGift(gift)" class="effect-hover btn btn-primary mr-2 text-xs p-2" target="_blank">detalhes</button>
                </div>
            </div>
        </Slide>

        <template #addons>
            <Navigation v-if="gifts.length > 2" />
        </template>
    </Carousel>
    <div v-else class="text-sm">
        <p>
            <small>
                Não há lista de presentes criada<br>
                <span v-if="!userSlug && currentPage != 'presente'">Acesse a página de <a class="btn-link" :href="baseUrl+'/presente'">presentes</a> para criar uma sua lista personalizada.</span>
                <span v-if="currentPage == 'presente'">Clique em <strong>adicionar presente</strong> para criar uma nova lista de presente</span>
            </small>
        </p>
    </div>
    <Modal v-if="showModal" @eventToggleModal="toggleModal">
        <img class="m-auto w-28 my-2" :src="giftDetails.image" alt="">
        <p class="text-sm font-semibold text-gray-500 px-3">
            <ul>
                <li class="py-4"><strong>{{giftDetails.name}}</strong></li>
                <li v-if="giftDetails.info" class="py-4">{{giftDetails.info}}</li>
            </ul>
        </p>
        <div class="my-2 mb-2 pl-4" :class="giftDetails.is_api === 1 ? 'justify-between' : 'justify-center'">
            <p v-if="giftDetails.price" class="block text-sm font-semibold text-primary-dark cursor-auto my-3">R${{giftDetails.price}}</p>
            <template v-if="giftDetails.url">
                <a class="btn btn-primary my-3 text-xs" :href="giftDetails.url" target="_blank">acessar site do produto</a>
                <div class="my-2">
                    <small class="text-main-gray">
                    <strong>Importante:</strong> Ao clicar em <u>acessar site do produto</u>, vc estará acessando um conteúdo externo e seu conteúdo é de responsabilidade de seus criadores.
                </small>
                </div>
            </template>
        </div>
    </Modal>
</template>
<script>
    import 'vue3-carousel/dist/carousel.css';
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

    import Modal from '@/components/includes/Modal';
    
    export default {
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
            Modal
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                userSlug: '',
                listOfGroups: [],
                gifts: {},
                showModal: false,
                giftDetails: '',
                currentPage: '',
                breakpointsGifts: {
                    100: {
                        itemsToShow: 1.8,
                    },
                    700: {
                        itemsToShow: 3.2,
                    },
                    1024: {
                        itemsToShow: 4.8,
                    },
                },
                dataAlert: {
                    layout: 'confirmation',
                    icon: '',
                    title: ''
                },
                responseGiftDelete: [{}],
                giftRemoved: false
            }
        },
        mounted() {
            this.checkUserSlug();
        },
        methods: {
            checkUserSlug() {
                const currentUrl = window.location.href;
                const perfilParam = currentUrl.split('/perfil/')[1];
                this.currentPage = currentUrl.split('/')[3];
                this.userSlug = perfilParam ?? '';
                this.getUserGitfs();
            },
            setDetailGift(data) {
                this.giftDetails = data;
                this.toggleModal(true);
            },
            toggleModal() {
                this.showModal = !this.showModal;
            },
            getUserGitfs() {
                let url = `${this.baseUrl}/api/v1/user/gifts/${this.userSlug}`;
                axios.get(url)
                    .then(response => {
                        this.gifts = response.data;
                    })
                    .catch(errors => {
                        if(process.env.APP_DEBUG) console.log('error: ', errors);
                    })
            },
            preareRemoveGift(gift) {
                let self = this;
                self.giftRemoved = false;
                let listOfGroups = [];
                let groups = gift.groups;

                groups.forEach((group) => {
                    let removeButton = `<button data-group="${group.id}" class="remove-gift-button text-secondary-dark p-2 outline-none" class="flex text-primary-dark items-center px-2 py-1 transition"><i class="fa-solid fa-trash-alt"></i></button>`
                    listOfGroups.push(
                        `<li class="text-sm flex justify-between border-b-2 p-2 bg-main-gray-light items-center h-full item-gift">${group.name} ${removeButton}</li>`
                    );
                });

                listOfGroups = listOfGroups.join('');

                Swal.fire({
                    title: 'Este presente está cadastrado nos grupos abaixo',
                    icon: 'warning',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    html: `<p class="py-2 text-sm">clique no ícone <strong><i class="fa-solid fa-trash-alt text-secondary-dark"></i></strong> para apagar este presente do grupo</p><ul class="list-none">${listOfGroups}</ul>`,
                    allowOutsideClick: false,
                    // footer: `
                    //     <button id="removeAllGroups" class="btn btn-secondary">Remover de todos os grupos</button>
                    // `,
                    didOpen() {
                        const removeAllGroups = document.getElementById('removeAllGroups');
                        if (removeAllGroups) {
                            removeAllGroups.addEventListener('click', async () => {
                                groups.forEach(group => {
                                    self.removeGift(group.id, gift.id);
                                });
                                self.giftRemoved = true;
                                Swal.close();
                            });
                        }

                        let buttonsDelete = document.querySelectorAll('.remove-gift-button');
                        buttonsDelete.forEach((button, index) => {
                            let groupId = button.getAttribute('data-group');
                            button.addEventListener('click', async () => {
                                const response =  await self.removeGift(groupId, gift.id);
                                if(response) {
                                    const elParent = button.parentElement;
                                    if (elParent) {
                                        elParent.remove();
                                        if(!self.giftRemoved) self.giftRemoved = true;
                                    }

                                    if (document.querySelectorAll('.item-gift').length === 0) {
                                        Swal.close();
                                    }
                                } else {
                                    console.log(response);
                                }
                            });
                        });
                    },
                    didClose() {
                        if(self.giftRemoved) {
                            const Toast = Swal.mixin({
                            toast: true,
                            position: 'top',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })
    
                            Toast.fire({
                                icon: 'success',
                                title: 'Presente(s) removido(s) com sucesso!'
                            });
                            self.giftRemoved = false;
                        }

                    }
                })
            },
            async removeGift(groupId, giftId) {
                
                let formData = new FormData();
                formData.append('_method', 'delete');
                let url = `${this.baseUrl}/api/v1/gift/${groupId}/${giftId}`;
                
                try {
                    const response = await axios.post(url, formData);
                    this.getUserGitfs();
                    return response;
                } catch(error) {
                    console.error(error.message)
                }
            },
        }
    }
</script>