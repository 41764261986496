<template>
    <div class="content-center my-10">
        <div class="flex justify-center">
            <div class="content-logo">
                <span class="logo flex justify-center">
                    <a href="/">
                        <img class="w-40 md:w-125px" :src="baseUrl+'/default/images/logo_meu_amigo_oculto_circle.png'">
                    </a>
                </span>
            </div>
        </div>
        <div class="flex justify-center w-full mt-5">
            <div class="sm:card w-5/6 md:max-w-sm mb-5">
                <div class="text-center pt-4">
                    <h2>Cadastrar</h2>
                </div>

                <notify-component v-if="showNotify" :alert="dataNotify"></notify-component>

                <div class="sm:card-body">
                    <form method="POST" action="" @submit.prevent="register($event)">
                        <input type="hidden" name="_token" :value="csrf_token">

                        <div class="form-field" :class="(name.length < 3 && name != '') || (btnClicked && name === '') ? 'error' : 'active'">
                            <label for="nome">nome</label>
                            <input id="name" type="text" class="input-form" v-model="name" name="name" autocomplete="name" autofocus>
                            <small v-if="btnClicked && name === ''" class="text-secondary-dark">O campo nome é obrigatório</small>
                            <small v-if="name.length < 3 && name != ''" class="text-secondary-dark">No mínimo 3 caracteres</small>
                        </div>

                        <div class="form-field" :class="btnClicked && email === '' ? 'error' : 'active'">
                            <label for="email">email</label>
                            <input id="email" type="email" class="input-form" v-model="email" name="email" autocomplete="email">
                            <small v-if="btnClicked && email === ''" class="text-secondary-dark">O campo email é obrigatório</small>
                            <small v-if="dataError.email" class="text-secondary-dark">
                                <ul>
                                    <li v-for="(msg, index) in dataError.email" :key="index">
                                        {{msg}}
                                    </li>
                                </ul>
                            </small>
                        </div>

                        <div class="form-field active" :class="btnClicked && dob === '' ? 'error' : 'active'">
                            <label for="data de nascimento">data de nascimento</label>
                            <input type="date" class="input-form" autocomplete="data de nascimento" v-model="dob" name="dob" :max="today">
                            <small v-if="btnClicked && dob === ''" class="text-secondary-dark">O campo data de nascimento é obrigatório</small>
                        </div>
                        
                        <div class="form-field" :class="(password.length < 8 && password != '') || (btnClicked && password === '') ? 'error' : 'active'">
                            <div class="icon-input">
                                <label for="senha">senha</label>
                                <input id="password" :type="this.showvalue.password ? 'text' : 'password'" v-model="password" class="input-form" name="password" autocomplete="new-password">
                                <a tabindex="-1" href="#" @click="toggleTypePass('password')">
                                    <i class="fa-solid" :class="this.showvalue.password ? 'fa-eye-slash' : 'fa-eye'"></i>
                                </a>
                            </div>
                            <small v-if="btnClicked && password === ''" class="text-secondary-dark">O campo senha é obrigatório</small>
                            <small v-if="password.length < 8 && password != ''" class="text-secondary-dark">No mínimo 8 caracteres</small>
                        </div>
                        <div class="form-field mb-8" :class="btnClicked && password_confirmation === '' ? 'error' : 'active'">
                            <div class="icon-input">
                                <label for="confirme a senha">confirme a senha</label>
                                <input id="password-confirm" :type="this.showvalue.confirm ? 'text' : 'password'" v-model="password_confirmation" class="input-form" name="password_confirmation" autocomplete="new-password">
                                <a tabindex="-1" href="#" @click="toggleTypePass('confirm')">
                                    <i class="fa-solid" :class="this.showvalue.confirm ? 'fa-eye-slash' : 'fa-eye'"></i>
                                </a>
                            </div>
                            <small v-if="btnClicked && password_confirmation === ''" class="text-secondary-dark">O campo confirme a senha é obrigatório</small>
                            <small v-if="dataError.password_confirmation" class="text-secondary-dark">
                                <ul>
                                    <li v-for="(msg, index) in dataError.password_confirmation" :key="index">
                                        {{msg}}
                                    </li>
                                </ul>
                            </small>
                        </div>
                        <div class="flex items-start mb-6">
                            <div class="flex items-center h-5">
                                <input id="newsletter" aria-describedby="newsletter" v-model="newsletter" type="checkbox" class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded">
                            </div>
                            <div class="text-sm ml-3">
                                <label for="newsletter">Quero receber atualizações por email</label>
                            </div>
                        </div>
                        <div class="flex items-start mb-6">
                            <div class="flex items-center h-5">
                                <input id="terms_privacy" aria-describedby="terms_privacy" v-model="terms_privacy" type="checkbox" class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded">
                            </div>
                            <div class="text-sm ml-3">
                                <label for="terms_privacy">Eu concordo com os <a target="_blank" :href="baseUrl+'/termos-de-uso'" class="cursor-pointer text-primary">Termos de Uso</a> e <a target="_blank" :href="baseUrl+'/politica-de-privacidade'" class="cursor-pointer text-primary">Política de Privacidade</a></label>
                                <small v-if="dataError.terms_privacy" class="text-secondary-dark">
                                    <ul>
                                        <li v-for="(msg, index) in dataError.terms_privacy" :key="index">
                                            {{msg}}
                                        </li>
                                    </ul>
                                </small>
                            </div>
                        </div>
                           
                        <div v-if="loading" class="text-center p-2">
                            <pulse-loader :color="color" :size="size"></pulse-loader>
                        </div>
                        <div v-else class="flex items-center justify-between">
                            <button class="btn btn-primary w-full" type="submit">
                                cadastrar
                            </button>
                        </div>
                    </form>
                </div>
                <div class="sm:card-footer flex justify-center py-10">
                    <span>Já possui uma conta? <a class="btn-link" href="/login">Entrar</a></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import base from '@/base';
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
    import Swal from 'sweetalert2';
    export default {
        props: ['csrf_token', 'data_error'],
        components: {
            PulseLoader
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                name: '',
                email: '',
                dob: '',
                password: '',
                password_confirmation: '',
                newsletter: false,
                terms_privacy: false,
                showvalue: {
                    'password': false,
                    'confirm': false
                },
                showNotify: false,
                loading: false,
                color: '#36BCF9',
                size: '15px',
                dataError: '',
                error: {type: '', message: ''},
                btnClicked: false,
                dataNotify: {},
                today: new Date().getFullYear() + "-" + (new Date().getMonth()+1) + "-" + new Date().getDate(),
            }
        },
        mounted() {
            base.labelAction();
        },
        methods: {
            prepareDataMessage(messages) {

                let list = document.createElement('ul');
                for (const values in messages) {
                    if (messages.hasOwnProperty(values)) {
                        let li = document.createElement('li');
                        li.innerHTML = `<strong>${values}:</strong> ${messages[values]}`;
                        list.appendChild(li);
                    }
                }
                
                return list;
            },
            register() {
                if(this.validateValues()) {
                    this.btnClicked = true;
                    return;
                }

                this.showNotify = false;
                this.loading = true;

                let url = `${this.baseUrl}/api/register`;
                let config = {
                     method: 'post',
                     body: new URLSearchParams({
                         'name': this.name,
                         'email': this.email,
                         'dob': this.dob,
                         'password': this.password,
                         'password_confirmation': this.password_confirmation,
                         'newsletter': this.newsletter ? 1 : 0,
                         'terms_privacy': this.terms_privacy ? 1 : 0,
                     })
                }
                fetch(url, config)
                    .finally(()=>{
                        this.loading = false;
                    })
                    .then(response => response.json())
                    .then(data => {
                        
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top',
                            showConfirmButton: false,
                            timer: 4500,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: data.type,
                            title: data.type == 'success' ? 'Cadastro realizado.' : 'Ops! Algo deu errado.',
                            html: data.type == 'success' ? data.message : 'Verifique as informações digitadas.'

                        })

                        if(data.type == 'success') this.cleanFields();

                        if(data.type == 'error') {
                            this.dataError = data.message;
                        }
                    })
                    .catch(error => {
                        this.showNotify = true; 
                        this.error.type = 'error';
                        this.error.message = error.message;
                    });

            },
            validateValues() {
                if (this.name && this.email && this.dob && this.password) {
                    return false;
                }
                return true;
            },
            cleanFields() {
                this.name = '';
                this.email = '';
                this.dob = '';
                this.password = '';
                this.password_confirmation = '';
                this.newsletter = false;
                this.terms_privacy = false;
            },
            toggleTypePass(type) {
                this.showvalue[type] = !this.showvalue[type];
            }
        }
    }
</script>
