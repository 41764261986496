<template>
    <footer class="bg-main-gray-light">
        <div class="w-4/5 h-72 flex flex-col m-auto p-5 sm:justify-around sm:flex-row sm:h-auto">
            <div class="w-full sm:w-auto py-5 text-center">
                <a :href="baseUrl">
                    <img class="w-40 m-auto md:w-32" :src="baseUrl+'/default/images/logo_meu-amigo-oculto.png'">
                </a>
            </div>
            <div class="w-full sm:w-auto py-5 text-center text-sm">
                <p>&copy; 2023 Meu Amigo Oculto</p>
            </div>
            <div class="w-full sm:w-auto py-5 text-center text-sm text-primary-dark">
                <i class="fa-solid fa-envelope text-sm"></i> contato@meuamigooculto.com.br
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    data() {
        return {
            baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
        }
    },
}
</script>