<template>
    <div class="perfil-header-top flex items-stretch justify-between">
        <button v-if="user.isLogged" class="w-1/3 text-sm btn bg-transparent text-left text-white" @click="parentEditProfile()"><i class="fa-solid fa-pen-to-square text-sm"></i> editar</button>
        <button v-if="user.isLogged" class="w-1/3 text-sm btn bg-transparent text-right text-white" onclick="document.getElementById('logout-form').submit();">
            <i class="fa-solid fa-right-from-bracket text-sm"></i> sair
        </button>
    </div>
    <div class="avatar" :style="'background-image: url('+user.image+')'"></div>
</template>

<script>
    export default {
        props: ['user', 'edit'],
        emits: ["eventToggleEditProfile"],
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
            }
        },
        mounted() {
        },
        methods: {
            parentEditProfile() {
                this.$emit('eventToggleEditProfile');
            }
        }
    }
</script>
