<template>
    <div class="container mb-20">
        <div v-if="!edit" class="md:card m-5">
            <div class="md:card-header perfil-header">
                <HeaderProfile :user="user" @eventToggleEditProfile="toggleEditProfile" :edit="edit"/>
            </div>
            <div class="md:card-body">
                <BodyProfile :user="user" :tab="tab" />
            </div>
        </div>
        <EditProfile v-else :user="user" @eventToggleEditProfile="toggleEditProfile"/>
    </div>

</template>

<script>
import HeaderProfile from '@/components/pages/profile/HeaderProfile';
import BodyProfile from '@/components/pages/profile/BodyProfile';
import EditProfile from '@/components/pages/profile/EditProfile';

export default {
    props: {
        data_user: '',
        tab: {
            default: ''
        }
    },
    components: {
        HeaderProfile,
        BodyProfile,
        EditProfile
    },
    data() {
        return {
            baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
            user: '',
            auth: '',
            tabs: [
                "Sobre",
                "Grupos",
                "Presentes",
            ],
            edit: false
        }
    },
    mounted() {
        this.user = JSON.parse(this.$props.data_user);
    },
    methods: {
        toggleEditProfile(reloadPage = false) {
            this.edit = !this.edit;
            if(reloadPage) {
                setTimeout(() => {                
                    window.location.reload();
                }, 1500);
            }
        }
    }
}
</script>