<template>
    
</template>
<script>
    import Swal from 'sweetalert2';

    export default {
        props: {
            alert: {
                type: Object,
                default: () => ({})
            }
        },
        emits: ['callParentSweetAlert'],
        mounted() {
            this.showAlert(JSON.parse(this.$props.alert));
        },
        methods: {
            showAlert(dataAlert) {
                switch (dataAlert.layout) {
                    case 'confirmation':
                        Swal.fire({
                            title: dataAlert.title,
                            text: dataAlert.text,
                            icon: dataAlert.type,
                            showCancelButton: true,
                            confirmButtonColor: '#36BCF9',
                            cancelButtonColor: '#d33',
                            confirmButtonText: dataAlert.confirmBtntxt,
                            cancelButtonText: dataAlert.cancelBtntxt,
                        }).then((result) => {
                            this.$emit('callParentSweetAlert', result);
                        })
                        break;

                    case 'html-content':
                        Swal.fire({
                            title: '<strong>HTML <u>example</u></strong>',
                            icon: 'info',
                            html:
                                'You can use <b>bold text</b>, ' +
                                '<a href="//sweetalert2.github.io">links</a> ' +
                                'and other HTML tags',
                            showCloseButton: true,
                            showCancelButton: true,
                            focusConfirm: false,
                            confirmButtonText:
                                '<i class="fa fa-thumbs-up"></i> Great!',
                            confirmButtonAriaLabel: 'Thumbs up, great!',
                            cancelButtonText:
                                '<i class="fa fa-thumbs-down"></i>',
                            cancelButtonAriaLabel: 'Thumbs down'
                        })
                        break;

                    default:
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top',
                            showConfirmButton: false,
                            timer: 4500,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: dataAlert.type,
                            title: dataAlert.title,
                            html: dataAlert.message
                        }).then(() => {
                            this.$emit('callParentSweetAlert');
                        })
                        break;
                }
            }
        }
    }
</script>