<template>
    <div class="content-center my-10">
        <div class="flex justify-center w-full">
            <a class="mb-3 cursor-pointer" :href="baseUrl+'/login'"> <i class="fa-solid fa-arrow-left mr-2"></i> Voltar para o login</a>
        </div>
        <div class="flex justify-center w-full mt-5">
            <div class="sm:card w-5/6 md:max-w-sm mb-10">
                <div class="text-center pt-4 pb-6">
                    <div class="bg-primary-dark rounded-full w-12 h-12 mx-auto my-0">
                        <i class="fa-solid fa-key text-white p-4 p-l1"></i>
                    </div>
                    <h3 class="mt-1">Crie uma senha nova</h3>
                    <notify-component v-if="showNotify" :alert="dataNotify"></notify-component>
                </div>
                <div>
                    <form method="POST" action="" @submit.prevent="resetPassword($event)" @keyup="checkFields($event)">
                        <input type="hidden" name="_token" :value="csrf_token">
                        <div class="row mb-3">
                            <div class="form-field active mt-1" :class="error['email'].length ? 'error' : 'active'">
                                <label for="email">email</label>
                                <input id="email" type="email" class="input-form lowercase" name="email" v-model="email" required autocomplete="email" autofocus>
                                <ul v-if="error['email'] && error['email'].length">
                                    <li v-for="(message, index) in error['email']" :key="index">
                                        <small class="text-secondary-dark">
                                            {{message}}
                                        </small>
                                    </li>
                                </ul>
                            </div>    
                        </div>

                        <div class="row mb-3">
                            <div class="form-field mt-1" :class="error['password'].length ? 'error' : 'active'">
                                <label for="email">senha</label>
                                <input v-model="password" id="password" type="password" class="input-form lowercase" name="password" required autocomplete="new-password">
                                <ul v-if="error['password'] && error['password'].length">
                                    <li v-for="(message, index) in error['password']" :key="index">
                                        <small class="text-secondary-dark">
                                            {{message}}
                                        </small>
                                    </li>
                                </ul>
                                <!-- <small v-if="password.length < 8 && password != ''" class="text-secondary-dark">No mínimo 8 caracteres</small> -->
                            </div>    
                        </div>
                        <div class="row mb-3">
                            <div class="form-field mt-1" :class="error['password_confirmation'].length ? 'error' : 'active'">
                                <label for="email">confirmar senha</label>
                                <input v-model="password_confirmation" id="password_confirmation" type="password" class="input-form lowercase" name="password_confirmation" required autocomplete="new-password">
                                <ul v-if="error['password_confirmation'] && error['password_confirmation'].length">
                                    <li v-for="(message, index) in error['password_confirmation']" :key="index">
                                        <small class="text-secondary-dark">
                                            {{message}}
                                        </small>
                                    </li>
                                </ul>
                            </div>  
                        </div>
                        <div class="row mb-0">
                            <div v-if="loading" class="text-center p-2">
                                <pulse-loader :color="color" :size="size"></pulse-loader>
                            </div>
                            <div v-else class="col-md-6 offset-md-4">
                                <button type="submit" class="btn btn-primary">Redefinir senha</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import base from './../../../../js/base';
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
    export default {
        props: ['csrf_token', 'token', 'data_email'],
        components: {
            PulseLoader
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                email: '',
                password: '',
                password_confirmation: '',
                showNotify: false,
                dataNotify: {},
                error: {
                    email: [],
                    password: [],
                    password_confirmation: []
                },
                loading: false,
                color: '#36BCF9',
                size: '15px',
            }
        },
        mounted() {
            base.labelAction();
            this.email = this.data_email;
        },
        methods: {
            checkFields(e) {
                
                switch (e.target.id) {
                    case 'password':
                        if(this.password.length < 8) {
                            let msg = 'Campo senha deve ter no  mínimo 8 caracteres';
                            if(this.error.password != msg) this.error.password[0] = msg;
                        } else {
                            this.error.password.splice(0,1)
                        }
                        break;
                    case 'password_confirmation':

                        this.error.password_confirmation = [];
                        if(this.password_confirmation.length < 8) {
                            let msg = 'Campo confirmação de senha deve ter no  mínimo 8 caracteres';
                            if(this.error.password_confirmation[0] != msg) this.error.password_confirmation[0] = msg;
                        } else {
                            this.error.password_confirmation.splice(0,1)
                        }
                        if(this.password_confirmation != this.password) {
                            let msg = 'A confirmação da senha não corresponde.';
                            if(this.error.password_confirmation[1] != msg) this.error.password_confirmation[1] = msg;
                        } else {
                            this.error.password_confirmation.splice(1,1);
                        }
                        break;
                    
                    case 'email':
                        if(this.email != this.data_email) {
                            let msg = 'Email divergente com o solicitado para redefinir senha';
                            if(this.error.email[0] != msg) this.error.email[0] = msg;
                        } else {
                            this.error.email.splice(0,1)
                        }
                        break;
                
                    default:
                        break;
                }
            },
            resetPassword(event) {
                this.loading = true;
                this.showNotify = false;
                let url = `${this.baseUrl}/api/password/reset`;
                let config = {
                     method: 'post',
                     body: new URLSearchParams({
                        '_token': this.csrf_token,
                        'email': this.email,
                        'token': this.token,
                        'password': this.password,
                        'password_confirmation': this.password_confirmation,
                     })
                }

                fetch(url, config)
                    .then(response => response.json())
                    .then(data => {
                        if(typeof data.message == 'string') {
                            this.showNotify = true.valueOf;
                            this.dataNotify.type = data.type;
                            this.dataNotify.message = data.message;
                            this.showNotify = true;

                            this.email = '';
                            this.password = '';
                            this.password_confirmation = '';
                        }else {

                        }
                        this.loading = false;
                    })

            }
        }
    }
</script>
