<template>
    <div class="flex flex-col flex-auto h-full p-6 mb-16">
        <p><small>Não se preocupe. Seu amigo <strong>não vai ver</strong> sua identidade ;)</small></p>
        <div class="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4">
            <div class="flex flex-col h-full overflow-x-auto mb-4">
                <div class="flex flex-col h-full">
                    <div id="messagesContainer" class="grid grid-cols-12 gap-y-2 overflow-y-scroll h-56 sm:h-96">
                        <div v-for="(message, index) in messages" :key="index" class="p-3 rounded-lg" :class="message.from == message.draw_id ? 'col-start-1 col-end-13' : 'col-start-1 col-end-8'">
                            <div class="flex items-center" :class="message.from == message.draw_id ? 'justify-start flex-row-reverse' : 'flex-row'">
                                <div v-if="message.from != message.draw_id" class="flex items-center justify-center h-10 w-10 rounded-full flex-shrink-0">
                                    <img :src="message.userImage" :alt="message.userName" class="rounded-full border overflow-hidden"/>
                                    <!-- <small>{{message.userName}}</small> -->
                                </div>
                                <div class="relative text-sm py-2 px-4 shadow rounded-xl" :class="message.from == message.draw_id ? 'mr-3 bg-indigo-100' : 'ml-3 bg-white'">
                                    {{message.message}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="messages.length === 0" class="text-center mt-2"> 
                    <p> Diga olá para quem você tirou <i class="fa-solid fa-hand-peace text-primary-dark"></i> </p>
                </div>
            </div>
            <div class="flex flex-row items-center h-16 rounded-sm w-full px-1">
                <div class="flex-grow">
                    <div class="relative w-full">
                        <input v-model="inputMsg" @keyup.enter="callBackSendMessage()" id="email" class="input-form lowercase" type="email" name="email" required autofocus/>
                    </div>
                </div>
                <div class="ml-4">
                    <button class="flex items-center justify-center bg-primary btn text-white py-4" @click="callBackSendMessage()">
                        <i class="fa-solid fa-paper-plane mx-2"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['messages_drawn'],
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                messages: '',
                inputMsg: ''
            }
        },
        mounted() {
            this.messages = this.messages_drawn;
        },
        methods: {
            callBackSendMessage() {
                this.$emit('createMessage', this.inputMsg);
            }
        }
    }
</script>
