<template>
    <h3 class="mt-3 mb-2">Convide os amigos</h3>
    <p>
        Escolha dentre as três opções abaixo como convidar seus amigos para o grupo:
    </p>
    <div v-if="group" class="my-5">
        <Tabs :tabs="configTabs" :hideTabText="false">
            <template v-slot:slot-copy>
                <InviteByLink :group="group"/>
            </template>
            <template v-slot:slot-user>
                <InviteByUser :groupSlug="group.slug"/>
            </template>
            <template v-slot:slot-email>
                <InviteByEmail :groupSlug="group.slug"/>
            </template>
        </Tabs>
    </div>
</template>

<script>
    import InviteByLink from './invite/InviteByLink';
    import InviteByUser from './invite/InviteByUser';
    import InviteByEmail from './invite/InviteByEmail';
    import Tabs from '@/components/includes/Tabs';

    export default {
        props: ['group'],
        components: {
            Tabs,
            InviteByLink,
            InviteByUser,
            InviteByEmail
        },
        data() {
            return {
                
                configTabs: [
                    {
                        title: 'Copie o link',
                        iconClass: 'fa-copy',
                        slotName: 'slot-copy'
                    },
                    {
                        title: 'Convide amigo já cadastrado',
                        iconClass: 'fa-user-plus',
                        slotName: 'slot-user'
                    },
                    {
                        title: 'Convide por email',
                        iconClass: 'fa-envelope',
                        slotName: 'slot-email'
                    },
                ]
            }
        }
    }

</script>