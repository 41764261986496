<template>
    <div class="md:card m-5">
        <div class="md:card-header perfil-header">
            <div class="perfil-header-top flex items-stretch content-around">
                <button @click="parentEditProfile()" class="w-2/4 text-sm btn bg-transparent text-left text-white"><i class="fa-solid fa-caret-left"></i> voltar</button>
            </div>
            <div v-if="imagePreview" @click="selectImagem($refs)" class="avatar cursor-pointer hover:opacity-80" :style="'background-image: url('+imagePreview+')'"></div>
            <input type="file" ref="file" class="hidden" @change="loadImagem($event)">
        </div>
    
        <div class="md:card-body">
            <div class="form-field" :class="error.slug ? 'error' : 'active'">
                <label for="slug">idenficador</label>
                <input v-model="user.slug" @blur="checkFieldExists($event)" id="slug" class="input-form lowercase" type="text" name="slug" required>
                <small v-if="error.slug" class="text-secondary-dark">
                    {{error.slug.length ? error.slug[0] : 'Este identificador já está sendo usado por outra pessoa.'}}
                </small>
            </div>
            <div class="form-field" :class="{ active: user.name }">
                <label for="name">nome</label>
                <input v-model="user.name" class="input-form lowercase" type="text" name="name" required>
            </div>
            <div class="form-field" :class="error.email ? 'error' : 'active'">
                <label for="email">email</label>
                <input v-model="user.email" @blur="checkFieldExists($event)" class="input-form lowercase" id="email" type="email" name="email" required>
                <small v-if="error.email" class="text-secondary-dark">
                    {{error.email.length ? error.email[0] : 'Este email já está sendo usado por outra pessoa.'}}
                </small>
            </div>
            <div class="form-field" :class="{ active: user.description }">
                <label for="description">descrição</label>
                <input v-model="user.description" class="input-form lowercase" type="text" name="description" required>
            </div>
            <div class="flex items-start mb-6">
                <div class="flex items-center h-5">
                    <input @click="changePassword()" id="changePass" aria-describedby="changePass" type="checkbox" class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded">
                </div>
                <div class="text-sm ml-3">
                    <label for="changePass">alterar senha</label>
                </div>
            </div>
            <template v-if="changePass">
                <div class="form-field" :class="error.password ? 'error' : 'active'">
                    <div class="icon-input">
                        <label for="senha">senha</label>
                        <input id="password" :type="this.showvalue.password ? 'text' : 'password'" v-model="password" class="input-form" name="password" autocomplete="new-password">
                        <a tabindex="-1" href="#" @click="toggleTypePass('password')">
                            <i class="fa-solid" :class="this.showvalue.password ? 'fa-eye-slash' : 'fa-eye'"></i>
                        </a>
                    </div>
                    <p class="text-sm text-gray-500">
                        <small>Mínimo 8 caracteres</small>
                    </p>
                    <small v-if="error.password" class="text-secondary-dark">
                        {{error.password.length ? error.password[0] : 'Verifique a senha informada.'}}
                    </small>
                </div>
                <div class="form-field mb-8" :class="error.password_confirmation ? 'error' : 'active'">
                    <div class="icon-input">
                        <label for="confirme a senha">confirme a senha</label>
                        <input id="password-confirm" :type="this.showvalue.confirm ? 'text' : 'password'" v-model="password_confirmation" class="input-form" name="password_confirmation" autocomplete="new-password">
                        <a tabindex="-1" href="#" @click="toggleTypePass('confirm')">
                            <i class="fa-solid" :class="this.showvalue.confirm ? 'fa-eye-slash' : 'fa-eye'"></i>
                        </a>
                    </div>
                    <p class="text-sm text-gray-500">
                        <small>A confirmação de senha deve ser igual ao campo senha</small>
                    </p>
                    <small v-if="error.password_confirmation" class="text-secondary-dark">
                        {{error.password_confirmation.length ? error.password_confirmation[0] : 'Verifique a senha informada.'}}
                    </small>
                </div>
            </template>
            <div v-if="loading" class="text-center p-2">
                <pulse-loader :color="color" :size="size"></pulse-loader>
            </div>
            <div v-else class="flex justify-end">
                <button @click="saveChanges()" class="btn btn-primary">
                    salvar alterações
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import base from '@/base';
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
    export default {
        props: ['user'],
        components: {
            PulseLoader
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                imagePreview: null,
                showInputFilme: false,
                changePass: '',
                showvalue: {
                    'password': false,
                    'confirm': false
                },
                password: '',
                password_confirmation: '',
                dataNotify: {type: '', message: ''},
                loading: false,
                color: '#36BCF9',
                size: '15px',
                error: {},
                oldValuesUser: ''
            }
        },
        mounted() {
            base.labelAction();
            this.oldValuesUser = this.$props.user;
            this.user.image = `${this.user.image}`;
            this.imagePreview = this.user.image;
        },
        methods: {
            parentEditProfile() {
                this.$emit('eventToggleEditProfile');
            },
            checkFieldExists(e) {
                
                let field = e.target.value;

                if(field) {
                    let url = `${this.baseUrl}/api/v1/validation/user/${e.target.id}/${field}`;
                    axios.get(url)
                        .then(resp => {
                            this.error[e.target.id] = resp.data ? true : false;
                        });
                }

            },
            changePassword() {
                this.password = '';
                this.password_confirmation = '';
                this.changePass = !this.changePass;
                setTimeout(()=>{
                    base.labelAction();
                },1)
            },
            toggleTypePass(type) {
                this.showvalue[type] = !this.showvalue[type];
            },
            selectImagem(input) {
                input.file.click();
            },
            loadImagem(e) {
                if(e.target.files.length > 0){
                    this.imagePreview = URL.createObjectURL(e.target.files[0]);
                    this.user.image = e.target.files;
                }
            },
            saveChanges() {
                this.loading = true;
                let url = `${this.baseUrl}/api/v1/user`;

                let data = new FormData()
                data.append('_method', 'patch');
                data.append('image', this.user.image[0]);
                data.append('slug', this.user.slug);
                data.append('name', this.user.name);
                data.append('email', this.user.email);
                data.append('description', this.user.description ?? '');
                
                if(this.changePass) {
                    data.append('password', this.password);
                    data.append('password_confirmation', this.password_confirmation);
                }

                let config = {
                     method: 'post',
                     body: data
                }

                fetch(url, config)
                    .finally(()=>{
                        this.loading = false;
                    })
                    .then(response => response.json())
                    .then(data => {
                        if(data.type == 'success') {
                            this.$emit('eventToggleEditProfile', true);
                        }
                        if(data.type == 'error') {
                            this.error = data.message
                        }

                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })
                        Toast.fire({
                            icon: data.type,
                            title: data.type == 'success' ? 'Cadastro atualizado.' : 'Ops! Algo deu errado.',
                            html: data.type == 'success' ? data.message : 'Verifique as informações digitadas.'
                        });
                    })
            },
            
        }
    }
</script>
