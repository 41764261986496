<template>
    <div class="mt-2 text-xs md:text-sm">
        <!-- BELLS -->
        <button v-if="group.access == 'creator' && !group.hasDraw"  @click="showUserRequestsModal = !showUserRequestsModal" class="notify-bells flex text-orange-400 sm:mr-8">
            <div id="bellNotification" class="bell-icon" tabindex="0">
                <svg class="" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="30px" viewBox="0 0 50 30" enable-background="new 0 0 50 30" xml:space="preserve">
                    <g class="bell-icon__group">
                        <path class="bell-icon__ball" id="ball" fill-rule="evenodd" stroke-width="1.5" clip-rule="evenodd" fill="none" stroke="#currentColor" stroke-miterlimit="10" d="M28.7,25 c0,1.9-1.7,3.5-3.7,3.5s-3.7-1.6-3.7-3.5s1.7-3.5,3.7-3.5S28.7,23,28.7,25z"/>
                        <path class="bell-icon__shell" id="shell" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#currentColor" stroke-width="2" stroke-miterlimit="10" d="M35.9,21.8c-1.2-0.7-4.1-3-3.4-8.7c0.1-1,0.1-2.1,0-3.1h0c-0.3-4.1-3.9-7.2-8.1-6.9c-3.7,0.3-6.6,3.2-6.9,6.9h0 c-0.1,1-0.1,2.1,0,3.1c0.6,5.7-2.2,8-3.4,8.7c-0.4,0.2-0.6,0.6-0.6,1v1.8c0,0.2,0.2,0.4,0.4,0.4h22.2c0.2,0,0.4-0.2,0.4-0.4v-1.8 C36.5,22.4,36.3,22,35.9,21.8L35.9,21.8z"/>
                    </g>
                </svg>
            </div>
            <span ref="textElement" class="text-info-invite hidden sm:flex align-middle">{{ requestList.length }} solicitações</span>
            <span class="tip-invite">{{ requestList.length }}</span>
        </button>
    </div>
    <!-- MODAL OF REQUEST USERS -->
    <transition name="fade">
       <div v-if="showUserRequestsModal && group.access == 'creator'" class="mobile-modal-content bg-opacity-75 h-vh">
           <div class="card relative h-4/6 sm:h-auto w-11/12 sm:w-11/12 lg:w-4/5">
               <button class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600" aria-label="close modal" role="button" @click="closeModal()">
                   <i class="fa-solid fa-close mr-2"></i>
               </button>
               <i class="fa-solid fa-mail-bulk text-primary-dark mr-2 text-xl mt-3"></i>
               <h3 class="mt-3 mb-1">Solicitações</h3>
               <p class="mb-2">Veja quem solcitou para entrar no seu grupo</p>
               <!-- :class="requestList.length > 0 ? 'overflow-y-scroll h-2/3' : ''" -->
               <div v-if="requestList.length > 0" class="grid grid-cols-1 sm:grid-cols-3 h-96 sm:h-auto overflow-y-scroll sm:overflow-y-visible">
                   <div v-for="(data, index) in requestList" :key="index" class="card effect-hover sm:mr-1 last:mr-0 mt-2">
                        <div class="flex items-center mt-2 rounded-lg px-1 py-1">
                            <div class="relative flex flex-shrink-0 items-end">
                                <img class="h-16 w-16 rounded-full" :src="data.userImage">    
                            </div>
                            <div class="ml-3">
                                <span class="font-semibold tracking-tight text-xs">{{data.userName}}</span>
                                <div class="flex my-2" v-if="!data.response">
                                    <button @click="responseRequest('accept', data)" class="btn bg-primary">aceitar </button>
                                    <button @click="responseRequest('refuse', data)" class="btn bg-secondary mx-1">recusar</button>
                                </div>
                                <div v-else-if="data.response == 'accept'">
                                    <p>Amigo aceito <i class="fa-solid fa-check-circle text-primary mr-2"></i></p>
                                </div>
                                <div v-else-if="data.response == 'refuse'">
                                    <p>Amigo recusado <i class="fa-solid fa-circle-xmark text-secondary-dark mr-2"></i></p>
                                </div>
                            </div>
                        </div>
                   </div>
               </div>
               <div v-else>
                   Não tem nenhuma solicitação no momento.
               </div>
           </div>
       </div>
   </transition>
</template>

<script>
    export default {
        props: ['group', 'requestList'],
        emits: ['parentAddUserToGroup'],
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                showInviteUsersModal: false,
                showUserRequestsModal: false,
                userList: '',
                showModalUserList: false
            }
        },
        methods: {
            closeModal() {
                this.showInviteUsersModal = false;
                this.showUserRequestsModal = false;
            },
            responseRequest(response, data) {

                if(response == 'accept') {
                    this.$emit('parentAddUserToGroup', data);
                }

                let requestId = data.requestId;
                let url = `${this.baseUrl}/api/v1/request/group/response`;

                let formData = new FormData();
                formData.append('response', response);
                formData.append('requestId', requestId);

                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                
                axios.post(url, formData, config)
                    .then(() => {
                        data.response = response;
                    })
                    .catch(errors => {
                        if(process.env.APP_DEBUG) console.log('errors: ', errors);
                    })
            }
        }
    }
</script>
