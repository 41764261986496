<template>
    <ul class="flex pb-6 justify-around">
        <template v-for="(tab, index) in tabs" :key="index">
            <li :class="['cursor-pointer transition py-2 px-4 text-center flex flex-col items-center', dinamicClass, activeTab === index ? 'bg-primary  rounded-md text-white md:text-primary md:bg-white' : 'text-gray-500']" @click="activeTab = index">
                <i class="fa-solid text-xl" :class="tab.iconClass"></i>
                <span :class="{'hideTextMobile': hideTabText}">{{tab.title}}</span>
            </li>
        </template>
    </ul>
    <template v-for="(content, index) in tabs" :key="index">
        <div v-if="activeTab == index" class="w-full bg-gray-50 rounded-sm p-3">
            <slot :name="content.slotName"></slot>
        </div>
    </template>
</template>
<script>
    export default {
        props: {
            tabs: '',
            firstTabActive: {
                default: false
            },
            hideTabText: {
                default: true
            }
        },
        data() {
            return {
                dinamicClass: '',
                activeTab: 0,
            }
        },
        mounted() {
            this.activeTab = this.$props.firstTabActive ? 0 : null
            // this.dinamicClass = `w-1/${this.$props.tabs.length}`;
        }
    }
</script>