<template>
    <div id="search-group" class="container pb-20 p-3 lg:p-0">
        <div class="mb-5">
            <h3>Pesquisar Grupo</h3>
        </div>

        <div class="relative form-fields  mb-3 sm:w-3/5">
            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                <svg fill="none" stroke="#a4a4a4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </span>
            <input v-model="filterTerm" type="search" name="q" class="py-2 text-sm input-form pl-10" placeholder="Digite o nome do grupo =)" autocomplete="off" v-on:keyup.enter="getGroupList()">
            <button v-if="true === false" class="btn mx-2 bg-primary text-white" @click="toggleContentFilter()">
                <i class="fa-solid fa-filter" :class="openContentFilter ? 'fa-filter-circle-xmark' : 'fa-filter'"></i>
            </button>
            <button type="button" class="btn btn-primary effect-hover ml-2" @click="getGroupList()">procurar</button>
        </div>
        <!-- content filter -->
        <div v-if="openContentFilter" class="w-full p-4 bg-main-gray-light container-filter-group">
            <h3>Filtrar grupo por:</h3>
            <div class="py-2 flex">
                <div class="mr-3">
                    <label class="label cursor-pointer text-sm">
                        <input type="checkbox" class="checkbox" value="groups" v-model="selectedOptions"/>
                        <span class="pl-2">grupos que fui convidado</span> 
                    </label>
                </div>
                <div class="mr-3">
                    <label class="label cursor-pointer text-sm">
                        <input type="checkbox" class="checkbox" value="nogroups" v-model="selectedOptions"/>
                        <span class="pl-2">grupos que participo</span> 
                    </label>
                </div>
                <div class="mr-3">
                    <label class="label cursor-pointer text-sm">
                        <input type="checkbox" class="checkbox" value="invites" v-model="selectedOptions"/>
                        <span class="pl-2">grupos que não participo</span> 
                    </label>
                </div>
            </div>
            <button class="btn btn-primary" @click="setFilterGroup()">
                Filtrar
            </button>
        </div>

        <div v-if="groups.length" class="flex justify-between mt-12">
            <h3>Grupos encontrados</h3>
        </div>
    
        <div v-if="loadingList" class="text-center p-2 mt-5">
            <pulse-loader :color="color" :size="size"></pulse-loader>
        </div>
        <div v-if="groups.length && !loadingList" class="w-full my-5">    
            <div class="search-group-list grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 place-content-start justify-items-start">
                <template v-for="(group, index) in groups" :key="index">
                    <div v-if="!group.sucess" class="flex mt-8 w-72 pb-5 flex-col bg-white rounded-2xl shadow-xl shadow-slate-300/60">
                        <a :href="baseUrl+'/grupo/'+group.slug" class="effect-hover">
                            <img class="aspect-video w-full rounded-t-2xl object-cover object-center" :src="group.image" />
                        </a>
                        <div class="p-4">
                            <a :href="baseUrl+'/grupo/'+group.slug" class="text-lg sm:text-base font-medium text-primary-dark pb-2 h-6">
                                {{ group.name.length > 25 ? group.name.substring(0,28)+"..." : group.name }} <i class="fa-solid fa-arrow-up-right-from-square"></i>
                            </a>
                            <p class="text-sm tracking-tight font-light text-slate-400 leading-6 h-6 truncate">{{group.description}}</p>
                        </div>
                        <div class="flex px-4">
                            <div class="group-avatar">
                                <div class="" v-for="(user, index) in group.users" :key="index">
                                    <img :src="user.image" alt=""/>
                                </div>
                            </div>
                            <span v-if="group.users.length > 2" :class="{'ml-5': group.users?.length === 4, 'ml-20': group.users?.length > 4}" class="mt-3 ml-5 text-gray-500 text-xs md:text-sm">+{{group.users.length - 3 }}</span>
                        </div>
                        <div v-if="!group.loadingRequest" class="flex justify-between px-4">
                            <button v-if="!group.belongsGroup && !group.hasRequired && !group.requestedNow && !group.hasDraw && !group.hasInvited" @click="requestGroup(group)" class="effect-hover btn bg-primary w-full p-4 sm:p-3 text-white text-sm">
                                pedir para participar
                            </button>
                            <button v-if="group.hasInvited" @click="showInvite(group)" class="effect-hover btn text-sm w-full p-4 sm:p-3 text-orange-500 bg-orange-100 blob-pulse-button">
                                acessar convite
                            </button>
                            <span v-if="group.hasRequired || group.requestedNow" class="w-full p-4 sm:p-3 text-sm flex items-center justify-center text-main-gray">aguardando aprovação</span>
                        </div>
                        <div v-else class="text-center p-2 mt-3">
                            <pulse-loader :color="color" :size="size"></pulse-loader>
                        </div>
                    </div>
                    <div v-if="group.sucess" class="flex h-96 justify-center items-center flex-col bg-white rounded-2xl mb-5 shadow-xl w-80 shadow-slate-300/60 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="svg-success m-5" width="200" viewBox="0 0 24 24">
                            <g stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
                            <circle class="success-circle-outline" cx="12" cy="12" r="11.5"/>
                            <circle class="success-circle-fill" cx="12" cy="12" r="11.5"/>
                            <polyline class="success-tick" points="17,8.5 9.5,15.5 7,13"/>
                            </g>
                        </svg>
                        <p class="text-sm text-primary">Solicitação enviada com Sucesso.</p>
                    </div>
                </template>
            </div>
        </div>

        <div v-if="!groups.length && !loadingList">
            <p class="text-main-gray p-3">
                <small>Nenhum grupo foi encontrado</small><br>
                <small>Tente pesquisar por uma nova palavra ou crie seu grupo.</small>
            </p>
        </div>
    </div>
</template>

<script>
    import ScrollReveal from 'scrollreveal';
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
    import Modal from '@/components/includes/Modal';

    export default {
        components: {
            PulseLoader,
            ScrollReveal,
            Modal,
        },
        mounted() {
            this.getGroupList();
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                openContentFilter: false,
                selectedOptions: [],
                group: {
                    name: '',
                    image: '',
                    description: '',
                    eventDate: ''
                },
                groups: {},
                filterTerm: '',
                loadingRequest: false,
                loadingList: false,
                color: '#36BCF9',
                size: '15px',
                debugTest: '',
                invite: [],
                showModalInviteGroup: false
            }
        },
        methods: {
            showInvite(group) {
                this.invite = [{
                    groupName: group.name,
                    groupSlug: group.slug,
                }]
                this.$emit('parentSetInvite', this.invite);
            },
            setFilterGroup() {
                // to do:  implements
                console.log(this.selectedOptions);
            },
            toggleContentFilter() {
                this.openContentFilter = !this.openContentFilter;
            },
            requestGroup(group) {
                group.loadingRequest = true;
                let url = this.baseUrl + `/api/v1/request/group`;
                let formData = new FormData();
                formData.append('slug', group.slug);

                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                axios.post(url, formData, config)
                    .finally(()=>{ group.loadingRequest = false; group.requestedNow = true;})
                    .then(response => {
                        group.sucess = true;
                        setTimeout(()=>{
                             group.sucess = false;
                        },3000);
                    })
                    .catch(errors => {
                        if(process.env.APP_DEBUG) console.log('error: ', errors);
                    })
            },
            getGroupList() {
                this.loadingList = true;
                this.groups = {};
                let url = `${this.baseUrl}/api/v1/group`;
                if(this.filterTerm != '') url = url + '?name=' + this.filterTerm;
                        
                axios.get(url)
                    .finally(()=>{ this.loadingList = false; })
                    .then(response => {
                        this.groups = response.data;
                        this.groups.forEach((item) => {
                            item.loadingRequest = false;
                            item.requestedNow = false;
                        });

                        setTimeout(() => {
                            this.revealAction();
                        },1)
                    })
                    .catch(errors => {
                        if(process.env.APP_DEBUG) console.log('error: ', errors);
                    })
            },
            revealAction() {
                ScrollReveal().reveal('.widget', { interval: 100, delay: 1, duration: 500, reset: true });
            },
        }
    }
</script>

<style lang="scss" scoped>
.svg-success {
  display: inline-block;
  vertical-align: top;
  height: 80px;
  width: 80px;
  opacity: 1;
  overflow: visible;

  @keyframes success-tick {
    0% {
      stroke-dashoffset: 16px;
      opacity: 1
    }

    100% {
      stroke-dashoffset: 31px;
      opacity: 1
    }
  }

  @keyframes success-circle-outline {
    0% {
      stroke-dashoffset: 72px;
      opacity: 1
    }

    100% {
      stroke-dashoffset: 0px;
      opacity: 1
    }
  }

  @keyframes success-circle-fill {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .success-tick {
    fill: none;
    stroke-width: 1px;
    stroke: #ffffff;
    stroke-dasharray: 15px, 15px;
    stroke-dashoffset: -14px;
    animation: success-tick 450ms ease 1400ms forwards;
    opacity: 0;
  }

  .success-circle-outline {
    fill: none;
    stroke-width: 1px;
    stroke: #81c038;
    stroke-dasharray: 72px, 72px;
    stroke-dashoffset: 72px;
    animation: success-circle-outline 300ms ease-in-out 800ms forwards;
    opacity: 0;
  }

  .success-circle-fill {
    fill: #81c038;
    stroke: none;
    opacity: 0;
    animation: success-circle-fill 300ms ease-out 1100ms forwards;
  }

  // Can't animate stroke-dashoffset on IE 10 and 11, just show svg instead
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .success-tick {
      stroke-dasharray: 0;
      stroke-dashoffset: 0;
      animation: none;
      opacity: 1;
    }

    .success-circle-outline {
      stroke-dasharray: 0;
      stroke-dashoffset: 0;
      animation: none;
      opacity: 1;
    }

    .success-circle-fill {
      animation: none;
      opacity: 1;
    }
  }
}
</style>
