<template>
    <div class="text-sm">
      <p>
          Você pode copiar o link abaixo e enviar para seu amigo (por whats, facebook, pombo correio, como preferir)
      </p>
      <p>
          Quando seu amigo acessar o link, ele entrará <strong>automaticamente</strong> para o grupo.
      </p>
      <p>
        <small>
          <strong>Importante:</strong><br>
          Enquanto o link de convite estiver <strong>ativo</strong>, qualquer pessoa que tiver cadastro e acessar esse link de convite terá entrada confirmada no grupo.</small>
      </p>
    </div>
    <div class="sm:flex sm:items-center py-3 px-5 bg-white">
      <p id="textToCopy" class="mr-2 text-primary-dark text-sm bg-main-gray-light p-2">
        <span class="block overflow-x-scroll sm:overflow-auto">{{  inviteLink && inviteHasAtived ? inviteLink : 'Link de convite desativado' }}</span>
      </p>
      <button v-if="inviteLink && inviteHasAtived" @click="copyInviteLink" class="btn flex bg-transparent border-2 w-full sm:w-auto" :class="copied ? 'border-green-500 text-green-500' : 'border-primary text-primary'">
        <i class="fa-solid mr-2" :class="copied ? 'fa-check' : 'fa-copy'"></i> <span>{{ copied ? ' link copiado' : ' copiar link' }}</span>
      </button>
    </div>
    <div class="flex items-center py-2 bg-white">
      <button class="btn w-full sm:w-auto text-orange-300 bg-white ml-2 justify-between items-center flex flex-col sm:flex-row" @click="actionInviteLink()">
        <i class="fa-solid fa-arrows-rotate sm:mr-2"></i> <span>{{ !inviteHasAtived ? 'ativar convite' : 'novo convite' }}</span> 
        </button>
        <button v-if="inviteHasAtived" class="btn w-full sm:w-auto text-red-400 bg-white justify-between items-center flex flex-col sm:flex-row" @click="actionInviteLink('disable')">
          <i class="fa-solid fa-ban sm:mr-2"></i> <span>desativar convite</span> 
        </button>
    </div>
  </template>
  <!-- <i class="fa-solid fa-check"></i> -->
  <script>
  export default {
    props: {
      group: {}
    },
    data() {
      return {
        baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
        copied: false,
        inviteLink: '',
        inviteHasAtived: false
      };
    },
    mounted() {
      this.initValues(this.$props.group);
    },
    methods: {
      initValues(group) {
        if(group.invite_hash && group.invite_hash_active) {
          this.prepareInviteLink(this.$props.group.invite_hash);
          this.inviteHasAtived = group.invite_hash_active;
        }
      },
      actionInviteLink(type = 'generate') {
        let url = `${this.baseUrl}/api/v1/group/invite/${type}/${this.group.slug}`;
        console.log(url);
        axios.get(url)
          .finally(()=>{  })
          .then(response => {
            if(type == 'generate') {
              this.prepareInviteLink(response.data);
              this.inviteHasAtived = true;
            } else {
              this.inviteHasAtived = false;
              this.inviteLink = '';
            }
          })
          .catch(errors => {
              if(process.env.APP_DEBUG) console.log('error: ', errors);
          })
      },
      prepareInviteLink(hash) {
        this.inviteLink = `${this.baseUrl}/invite/${this.group.slug}/${hash}`;
      },
      copyInviteLink() {
        navigator.clipboard.writeText(textToCopy.innerText).then(() => {
            this.copied = true;
            setTimeout(() => {
              this.copied = false;
            }, 2000);
          })
          .catch((error) => {
            console.error('Erro ao copiar o texto:', error);
          });
      }
    }
  };
  </script>
  