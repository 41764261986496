<template>
    <div id="modal" class="mobile-modal-content bg-opacity-75" @click="parentToggleModal()">
        <div class="md:w-3/6">
            <div class="rounded-xl shadow-xl card h-max">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            modalTitle: {
                type: String
            },
            modalContent: {
                default: null
            }
        },
        methods: {
            
            parentToggleModal(){
                this.$emit('eventToggleModal');
            }
        }
    }
</script>