<template>
    <div id="modal-content" class="mobile-modal-content bg-opacity-75" @click="closeModal()">
        <div class="flex flex-col items-center text-xs md:text-sm px-2 card h-3/6">
            <h2 class="my-2"><i class="fa-solid fa-user-group"></i> Amigos do grupo</h2>
            <ul class=" p-4 w-96 overflow-y-scroll h-4/5 bg-gray-50">
                <li v-for="(user, index) in userList" :key="index" class="my-3 effect-hover hover:bg-main-gray-light">
                    <a :href="baseUrl+'/perfil/'+user.slug" class="flex">
                        <img class="w-14 rounded-full" :src="user.image" alt=""/> <span class="flex flex-col justify-center ml-3">{{user.name}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    
    export default {
        props: ['userList'],
        
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br'
            }
        },
        methods: {
            closeModal() {
                this.$emit('callBackCloseModalUsers');
            }
        }
    }
</script>
