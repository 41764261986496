<template>
    <div class="w-full absolute bottom-0" v-for="(info, index) in listInfo" :key="index">
        <div v-if="activePage == index">
            <img class="rounded-t-lg w-full" :src="baseUrl+'/default/images/mobile/'+info.image" alt="" />
            <div class="py-4 px-8 bg-primary text-center rounded-t-lg ">
                <h1 class="mt-2 text-white font-bold text-2xl tracking-tight">
                    {{info.title}}
                </h1>
                <p class="py-3 text-white leading-6">
                    {{info.description}}
                </p>
                <div class="flex justify-between mt-6 mb-3 pb-16">
                    <a class="text-white" :href="baseUrl+'/login'">Pular</a>
                    <div class="flex bottom-5 left-1/2 z-30 space-x-3">
                        <label class="w-3 h-3 rounded-full" 
                            v-for="(bullet) in listInfo.length" :key="bullet" 
                            :class="{'bg-white': ( activePage + 1) == bullet, 'bg-slate-200': ( activePage + 1) < bullet, 'bg-white opacity-60': ( activePage + 1) > bullet}">
                        </label>
                    </div>
                    <label v-if="!(listInfo.length === (index + 1) )" @click="activePage = activePage + 1" class="cursor-pointer translate-x-5 text-white active:translate-y-0.5">Próximo</label>
                    <a v-else class="text-white" :href="baseUrl+'/login'">Próximo</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                activePage: 0,
                listInfo: [
                    {
                        image: 'divirta-se.png',
                        title: 'divirta-se com o amigo oculto',
                        description: 'junte todos os seus amigos e familiares para se divertir de forma fácil e prática'
                    },
                    {
                        image: 'presentes.png',
                        title: 'crie o seu grupo de amigo oculto',
                        description: 'crie e personalize seu grupo, convide seus amigos, monte sua lista de presentes, realize o sorteio e muito mais'
                    },
                    {
                        image: 'call.png',
                        title: 'converse com quem você tirou de forma anônima',
                        description: 'temos um chat totalmente anônimo para você tirar aquela dúvida com quem você sorteou sem estragar a brincadeira'
                    },
                    {
                        image: 'create.png',
                        title: 'crie lista de presentes',
                        description: 'você pode criar lista de presentes personalizadas para cada grupo que participa'
                    },
                    {
                        image: 'amigos.png',
                        title: 'realize o sorteio e divirta-se',
                        description: 'o sorteio é feito online e seus amigos são avisados por email'
                    },
                ]
            }
        },
        mounted() {
        },
        methods: {
            
        }
    }
</script>
