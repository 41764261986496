<template>
    <div class="container sm:w-4/5 p-5 md:p-0">
        <div class="flex justify-between items-center py-10">
            <h3>{{showForm ? 'Criar lista de presentes' : 'Meus presentes'}}</h3>
            <button class="effect-hover btn btn-primary p-2" @click="showForm = !showForm" :disabled="!hasGroup"> {{showForm ? 'meus presentes' : 'adicionar presente'}}</button>
        </div>
        <template v-if="hasGroup">
            <MyGifts v-if="!showForm" />
            <Create v-if="showForm" :group_collection="group_collection" />
        </template>
        <div v-else class="p-2 bg-main-gray-light text-sm">
            <p>
                <small>
                    Você precisa estar partificpando de um grupo para associar o presente.<br>
                    Clique <a class="btn-link" :href="baseUrl+'/grupo'">aqui</a> para procurar um grupo ou criar o seu.
                </small>
            </p>
        </div>
    </div>
</template>

<script>
    import Create from '@/components/pages/gift/Create.vue';
    import MyGifts from "@/components/includes/MyGifts";

    export default {
        props: ['group_collection'],
        components: {
            MyGifts,
            Create
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                showForm: false,
                hasGroup: ''
            }
        },
        mounted() {
            let grupos = JSON.parse(this.$props.group_collection);
            this.hasGroup = Object.keys(grupos).length;
        },
        methods: {
        }
        
    }
</script>