<template>
    <div class="m-auto sm:w-5/6 w-full">
        <div class="card">
            <h3>Estatísticas</h3>
        </div>
    </div>

</template>

<script>

export default {
    data() {
        return {
            baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
        }
    },
    mounted() {
        
    },
    methods: {
        
    }
}
</script>