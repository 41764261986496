<template>
    <div class="container flex h-5/6 antialiased text-gray-800">
        <div class="flex flex-row h-2/6 w-full overflow-x-hidden flex-wrap md:flex-nowrap">
            <Info v-if="drawnInfo" :info_drawn="drawnInfo" :groupSlug="dataDraw?.groupSlug" />
            <Chat v-if="messages" :messages_drawn="messages" @createMessage="sendMessage" />
        </div>
    </div>
</template>

<script>
    import Chat from "./Chat.vue";
    import Info from "./Info.vue";
    export default {
        components: {
            Chat,
            Info
        },
        props: ['data_draw'],
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                dataDraw: null,
                drawnInfo: null,
                messages: false,
                teste: false
            }
        },
        mounted() {
            this.dataDraw = JSON.parse(this.data_draw);
            this.drawnInfo = this.dataDraw.infoDrawn;
            this.getChatContent(this.dataDraw.groupSlug);
        },
        methods: {

            getChatContent(groupSlug) {
                this.messages = '';
                let url = `${this.baseUrl}/api/v1/draw/chat/${groupSlug}`;

                axios.get(url)
                    .then(response => {
                        this.messages = response.data;
                    })
                    .catch(errors => {
                        if(process.env.APP_DEBUG) console.log('error: ', errors);
                    })
            },
            sendMessage(message) {
                
                let url = `${this.baseUrl}/api/v1/draw/chat/${this.dataDraw.groupSlug}`;
               
                let formData = new FormData();
                formData.append('from', this.dataDraw.draw_id);
                formData.append('to', this.dataDraw.drawn_id);
                formData.append('drawId', this.dataDraw.id);
                formData.append('message', message);

                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                axios.post(url, formData, config)
                    .then(response => {
                        this.getChatContent(this.dataDraw.groupSlug);
                    })
                    .catch(errors => {
                        if(process.env.APP_DEBUG) console.log('errors::::: ', errors);
                    })
            }
        }
    }
</script>
