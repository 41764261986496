let base = {

    labelAction() {

        document.querySelectorAll('.input-form').forEach(input => {  
            let parent = input.closest('.form-field');
            input.addEventListener('focus', event => {
                if(parent) {
                    parent.classList.add('active');
                }
            });
            input.addEventListener('blur', event => {
                if(parent) {
                    if(!input.value.length && input.type != 'date') parent.classList.remove('active');   
                }
            });
        });
        
        
        let checkList = document.getElementById('list1');
        if(checkList) {
            checkList.getElementsByClassName('anchor')[0].onclick = function(evt) {
              if (checkList.classList.contains('visible'))
                checkList.classList.remove('visible');
              else
                checkList.classList.add('visible');
            }
        }
    }
}

export default base
