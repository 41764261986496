const { default: axios } = require('axios');
import Swal from 'sweetalert2';

window._ = require('lodash');

try {
    require('bootstrap');
} catch (e) {}


window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.request.use(function (config) {
    let token = document.cookie;
                
    token = token.split(';').find(i => {
        return i.includes('token=')
    });
    
    token = token.split('=')[1];

    config.headers.Accept = 'application/json';
    config.headers.Authorization = token;

    return config;
  })


axios.interceptors.response.use(
    response => {
        return response;
    },
    
    error => {
        if(error.response.status == 401) {
            axios.post(`${process.env.MIX_APP_URL}/api/refresh`)
                .then(response => {
                    document.cookie = `token=${response.data.token}`;
                })
                .catch(function (error) {

                    // document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'warning',
                            title: 'Sua sessão expirou. Por favor, faça login novamente.'
                        })

                        setTimeout(() => {
                            axios.post(`${process.env.MIX_APP_URL}/logout`)
                                .then(() => {
                                    // Limpar o token
                                    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

                                    const Toast = Swal.mixin({
                                        toast: true,
                                        position: 'top',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', Swal.stopTimer);
                                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                                        }
                                    });

                                    Toast.fire({
                                        icon: 'warning',
                                        title: 'Você foi deslogado devido à expiração da sessão.'
                                    });

                                    // Redirecione o usuário para a página de login
                                    window.location.href = '/login';
                                })
                                .catch(function (error) {
                                    // Em caso de erro na solicitação de logout
                                    console.error('Erro ao fazer logout:', error);
                                });
                        },3000)
                });
        }

        return Promise.reject(error);
    }
);