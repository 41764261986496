<template>
    <div class="text-sm">
        <p>Digite o email abaixo que enviaremos o convite.</p>
        <p>Após adicionar o(s) email(s) abaixo, clique em <strong>convidar</strong> para enviar o convite</p>
    </div>
    <div class="mt-5">
        <TagInput :emailsWrote="emailsWrote" @addEmailToINvite="addEmailToINvite"></TagInput>
        <p class="hidden sm:block py-2 text-orange-500 text-sm">
            <i class="fa-sharp fa-solid fa-circle-info"></i> <small>após inserir o email, precione <strong>enter</strong> ou <strong>tab</strong> para confirmar o email.</small>
        </p>
    </div>
    <div v-if="loadingSendInvite" class="text-center p-2">
        <pulse-loader :color="color" :size="size"></pulse-loader>
    </div>
    <div v-else class="flex justify-end mt-5">
        <button class="btn bg-primary w-full sm:w-auto text-white" @click="inviteUser()">enviar convites {{emails.length > 0 ? '('+emails.length+')' : ''}}</button>
    </div>
    
</template>

<script>
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
    import TagInput from "@/components/pages/group/detail/invite/TagInput";
    import Swal from 'sweetalert2';

    export default {
        props: ['groupSlug'],
        components: {
            PulseLoader,
            TagInput
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                emails: [],
                emailsWrote: [],
                emailsAdded: [],
                listSearchUsers: [],
                color: '#36BCF9',
                size: '15px',
                loading: false,
                loadingSendInvite: false,
                isMobile: false
            }
        },
        mounted() {
            this.isMobile = window.innerWidth <= 640;
        },
        methods: {
            cleanFields() {
                this.loading = false;
                for (let index = 0; index < this.emails.length; index++) {
                    this.emails[index] = '';
                }
            },
            addEmailToINvite(user) {

                // armazenar a lista de email que foi tagueada.
                this.emailsWrote = user;
                // possui itens adicionados?
                if (this.emailsWrote.length > 0 && this.emailsWrote.some(value => { return value.valid === true } )) {
                    // pegar apenas o email e armazenar nessa nova lista para salvar
                    this.emails = [...this.emailsWrote.filter((e) => e.valid === true).map(function (el) { return el.adress; })];
                }
            },
            inviteUser() {
                    if(this.emails.length) {
                        this.loadingSendInvite = true;
                        let url = `${this.baseUrl}/api/v1/group/invite/${this.groupSlug}`;
                        
                        let formData = new FormData();
                        formData.append('emails', this.emails);
    
                        let config = {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
    
                        axios.post(url, formData, config)
                                .then(response => {
                                    
                                    const Toast = Swal.mixin({
                                    toast: true,
                                    position: 'top',
                                    showConfirmButton: false,
                                    timer: 4500,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: response.data.type,
                                    title: response.data.type == 'success' ? 'Oba!' : 'Ops! Algo deu errado.',
                                    html: response.data.message

                                })
                            })
                            .then(()=>{
                                this.loadingSendInvite = false;
                                this.cleanFields();
                            })
                            .catch(errors => {
                                this.loadingSendInvite = false;
                            });
                        
                    }
                }
        }
    }

</script>