<template>
    <div class="flex justify-between items-center px-5">
        <div class="">
            <h2 class="mt-5">{{group.name}}</h2> 
            <p class="text-base text-main-gray" v-if="group.description">{{group.description}}</p>
            <div class="group-info my-3">
                <p class="text-base text-main-gray">Grupo criado em: {{ group.created }}</p>
                <p v-if="group.hasDraw" class="text-base text-main-gray">Ultimo sorteio: {{ group.drawCreated }}</p>
            </div>
            <p v-if="hasRecentRequest || group.hasRequest" class="my-2 text-lg text-secondary-dark">
                {{ group.drawCreated ? 'não é possível entrar neste grupo no momento' : 'aguardando aprovação' }}
            </p>
            <p class="mt-2 text-sm">
                <i class="fa-solid fa-calendar-alt mr-1"></i> 
                <span v-if="group.event_day"><strong>entrega de presentes:</strong> {{group.event_day+'/'+group.event_month+'/'+group.event_year}}</span>
                <span v-else>Não há data definida</span>
            </p>
        </div>
        <div class="self-end" v-if="!group.hasDraw && !group.access && !group.hasRequest && !hasRecentRequest">
            <span>
                <a class="btn btn-primary cursor-pointer" @click="prepareRequest()">
                    <i class="fa-solid mr-2 fa-people-group"></i>
                    <span class="hidden sm:contents">Pedir para participar</span>
                </a>
            </span>
        </div>
    </div>
    <div class="flex items-center text-lg md:text-sm px-5">
        <div>
            <div class="flex mr-5" @click="showAllUsers(group.users)">
                <div class="group-avatar">
                    <template v-for="(user, index) in group.users">
                        <div v-if="index <= 2" :key="index">
                            <img :src="user.image" alt="" />
                        </div>
                    </template>
                </div>
                <span class="cursor-pointer mt-3 text-gray-500 text-lg md:text-sm" :class="{'ml-5': group.users?.length === 4, 'ml-2': group.users?.length > 4}" v-if="group.users?.length > 2">+{{group.users.length - 3 }}</span>
            </div>
        </div> 
        <ModalUserList v-if="showModalUserList" :userList="group.users" @callBackCloseModalUsers="closeModalUsers" ></ModalUserList>
    </div>
    <sweetalert-component v-if="showSweetAlert" :alert="dataAlert" @callParentSweetAlert="callBackSweetAlert"></sweetalert-component>
    <template v-if="loading">
        <div class="fixed top-0 left-0 bg-opacity-80 bg-white h-full w-full"></div>
        <div class="flex justify-center">
            <pulse-loader :color="color" :size="size"></pulse-loader>
        </div>
    </template>
</template>

<script>
    import ModalUserList from '@/components/includes/ModalUserList';
    import SweetAlert from "@/components/includes/SweetAlert";
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

    export default {
        components: {
            ModalUserList,
            SweetAlert,
            PulseLoader
        },
        props: ['group'],
        mounted() {
            this.hasRecentRequest = this.$props.group.hasRequest ?? false;
        },
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                loading: false,
                color: '#36BCF9',
                size: '15px',
                userList: '',
                showModalUserList: false,
                showSweetAlert: false,
                dataAlert: {
                    layout: 'confirmation',
                    type:'warning',
                    title: 'Pedir para participar desse grupo?',
                    text: 'O administrador do grupo será notificado',
                    message: '',
                    confirmBtntxt: 'Sim, pedir para entrar',
                    cancelBtntxt: 'cancelar'
                },
                hasRecentRequest: false,
            }
        },
        methods: {
            callBackSweetAlert(result) {
                this.showSweetAlert = false;
                if (result.isConfirmed) {
                    this.loading = true;
                    setTimeout(()=>{
                        this.requestGroup();
                    },500)  
                }
                
            },
            closeModalUsers() {
              this.showModalUserList = false;  
            },
            showAllUsers(users) {
                this.userList = users;
                this.showModalUserList = true;
            },
            prepareRequest() {
                this.showSweetAlert = false;
                if(this.group.hasRequest || this.hasRecentRequest) {
                    this.dataAlert.layout = '';
                    this.dataAlert.type = 'warning';
                    this.dataAlert.title = 'Ops! Já existe uma solicitação';
                    this.dataAlert.message = 'Aguarde aprovarem sua entrada neste grupo';
                }
                this.showSweetAlert = true;
            },
            requestGroup() {

                let url = this.baseUrl + `/api/v1/request/group`;
                let formData = new FormData();
                formData.append('slug', this.group.slug);

                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                
                axios.post(url, formData, config)
                    .finally(()=>{ this.group.loadingRequest = false; this.group.requestedNow = true;})
                    .then(response => {
                        this.loading = false;
                        this.hasRecentRequest = true;
                        this.dataAlert.layout = '';
                        this.dataAlert.type = 'success';
                        this.dataAlert.title = 'Oba! Enviamos seu pedido'; 
                        this.dataAlert.message = 'Agora é só aguardar o moderador aceitar seu pedido ;)';
                        this.showSweetAlert = true;
                    })
                    .catch(errors => {
                        // this.showNotify = true;
                        // this.dataNotify = errors;
                        // this.$emit('showNotifyModal', this.dataNotify);
                    })
            },
        }
    }
</script>