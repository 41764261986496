<template>
     <div class="flex flex-col py-8 pl-2 pr-2 w-full md:w-64 bg-white flex-shrink-0">
        
        <button class="btn border-t-main-gray mb-3" @click="backTo()"> <i class="fa-solid fa-arrow-left mr-2"></i> Voltar</button>
        
        <div class="flex flex-row items-center h-12 w-full">
            <div class="flex items-center justify-center h-10 w-10">
                <img class="" :src="baseUrl+'/default/images/logo-circle-azul.png'">  
            </div>
            <div class="ml-2 font-bold text-sm">
                Meu Amigo Oculto é:
            </div>
        </div>
        <div class="flex flex-col justify-center h-4/5 items-center bg-primary border border-gray-200 mt-4 w-full py-6 px-4 rounded-lg">
            <template v-if="showDrawInfo">
                <div class="h-20 w-20 rounded-full border overflow-hidden">
                    <img
                    :src="infoDrawn.image"
                    :alt="infoDrawn.name"
                    class="h-full w-full"
                    />
                </div>
                <div class="text-sm font-semibold mt-2">{{infoDrawn.name}}</div>
                <div class="text-xs text-white">{{infoDrawn.email}}</div>
                <div v-if="infoDrawn.description != 'null'" class="text-xs text-white"><small>{{infoDrawn.description}}</small></div>
            </template>
            <template v-else>
                <i class="text-9xl fa-solid fa-question text-white p-4 px-5"></i>
            </template>
            <button @click="toggleDraw()">
                <i :class="showDrawInfo ? 'fa-eye-slash' : 'fa-eye'" class="fa-sharp fa-solid"></i>
                {{showDrawInfo ? 'ocultar' : 'mostar'}}
            </button>
        </div>
        <div class="flex flex-col mt-8">
            <div class="flex flex-row items-center justify-between text-xs">
                <span class="font-bold">Lista de Presentes</span>
                <span class="flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full">{{qtyGifts}}</span>
            </div>
            <div  class="flex flex-col space-y-1 mt-4 -mx-2 h-80 overflow-y-auto" v-if="showDrawInfo && qtyGifts > 0">
                <div v-for="(gift, index) in infoDrawn.gifts" :key="index" class="items-center hover:bg-gray-100 rounded-xl p-2">
                    <a :href="gift.url ? gift.url : '#'" target="_blank" class="flex flex-row ">
                        <div class="h-10 w-10 rounded-full border overflow-hidden">
                            <img
                            :src="gift.image"
                            :alt="gift.name"
                            class="h-full w-full"
                            />
                        </div>
                        <small class="ml-2">{{gift.name}}</small>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['info_drawn', 'groupSlug'],
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                infoDrawn: '',
                qtyGifts: null,
                showDrawInfo: false
            }
        },
        mounted() {
            this.infoDrawn = this.info_drawn;
            this.qtyGifts = this.infoDrawn.gifts.length;
        },
        methods: {
            backTo () {
                window.location.href = `${this.baseUrl}/grupo/${this.groupSlug}`;
            },
            toggleDraw() {
                this.showDrawInfo = !this.showDrawInfo;
            }
        }
    }
</script>
