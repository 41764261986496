<template>
    <div class="bg-center bg-cover h-72 mt-8 rounded-t-lg" :style="'background-image: url('+group.image+')'">
        <div v-if="group.access == 'creator'" class="flex flex-col items-end mr-2">
            <button @click="toggleNavGroup = !toggleNavGroup" :class="{'rounded-b-none text-primary-dark': toggleNavGroup}" class="btn bg-white mt-2 text-lg py-1 sm:text-sm">
                <i class="fas " :class="toggleNavGroup ? 'fa-times' : 'fa-cog'"></i>
            </button>
            <transition name="fade">
                <div v-if="toggleNavGroup" class="bg-opacity-75 text-lg sm:text-sm">
                    <ul class="flex flex-col items-end bg-white min-h-min rounded-b-sm rounded-tl-sm">
                        <li class="flex w-full">
                            <a class="btn text-primary-dark bg-transparent " :href="baseUrl+'/grupo/'+group.slug+'/editar'">
                                <i class="fa-solid fa-edit"></i>
                                editar grupo
                            </a>
                        </li>
                        <li class="flex w-full" v-if="!group.hasDraw">
                            <button class="btn text-primary-dark bg-transparent" @click="this.$emit('parentToggleMain')">
                                <i class="fa-solid fa-paper-plane"></i>
                                convidar amigos
                            </button>
                        </li>
                        <li class="flex w-full" v-if="group.users.length > 2 && !group.hasDraw">
                            <button class="btn text-primary-dark bg-transparent" @click="setDraw()">
                                <i class="fa-solid fa-wand-magic-sparkles"></i>
                                realizar sorteio
                            </button>
                        </li>
                        <li class="flex w-full" v-if="group.hasDraw">
                            <button class="btn text-secondary-dark bg-transparent" @click="cancelDraw()">
                                <i class="fa-solid fa-users-slash"></i>
                                cancelar sorteio
                            </button>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2';

export default {
    props: ['group'],
    emits: ['parentToggleMain'],
    components: {
        PulseLoader,
    },
    data() {
        return {
            baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
            dataNotify: {},
            color: '#36BCF9',
            size: '15px',
            loadingPage: false,
            toggleNavGroup: false,
        }
    },
    methods: {
        setDraw() {
            Swal.fire({
                title: 'Tem certeza que deseja realizar o sorteio?',
                text: "Seus amigos serão avisados por email",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#36BCF9',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, realizar sorteio!',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loadingPage = true;
                    let url = `${this.baseUrl}/api/v1/draw/group/${this.group.slug}`;
                    axios.post(url)
                        .finally(()=>{ this.loadingPage = false;})
                        .then(response => {
                            this.group.hasDraw = true;
                            Swal.fire({
                                title:'Sorteio realizado com sucesso!',
                                text:'Veja quem você tirou e converse com quem tirou você.',
                                icon:'success',
                                confirmButtonColor: '#36BCF9'
                            })

                            this.$emit('showNotifyModal', this.dataNotify);
                            
                        })
                        .catch(errors => {
                            if(process.env.APP_DEBUG) console.log('errors::::::::: ', errors);
                            this.showNotify = true;
                            this.dataNotify = errors;

                            this.$emit('showNotifyModal', this.dataNotify);
                        })
                }
            })
        },
        cancelDraw() {

            Swal.fire({
                title: 'Tem certeza que deseja cancelar o sorteio?',
                text: "Seus amigos serão avisados por email",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#36BCF9',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, deletar!',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if(result.isConfirmed) {
                    this.loadingPage = true;
                    let url = `${this.baseUrl}/api/v1/draw/cancel/group/${this.group.slug}`;

                    axios.post(url)
                        .finally(()=>{ this.loadingPage = false;})
                        .then(response => {
                            this.group.hasDraw = false;
                            Swal.fire({
                                title:'Sorteio cancelado com sucesso!',
                                text:'Email enviado para avisar seus amigos',
                                icon:'success',
                                confirmButtonColor: '#36BCF9'
                            });
                            this.$emit('showNotifyModal', this.dataNotify);
                        })
                        .catch(error => {
                            if(error.response.data)
                            this.showNotify = true;
                            this.dataNotify = error;

                            this.$emit('showNotifyModal', this.dataNotify);
                        })
                }
            })
        },
        requestGroup(group) {
            // group.loadingRequest = true;
            let url = this.baseUrl + `/api/v1/request/group`;
            let formData = new FormData();
            formData.append('slug', group.slug);

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            axios.post(url, formData, config)
                .finally(()=>{ group.loadingRequest = false; group.requestedNow = true;})
                .then(response => {
                    group.hasRequest = true;
                    this.$emit('updateGroup', 'request', true);

                    this.dataNotify.type = "success";
                    this.dataNotify.message = "Convite enviado com sucesso.";
                    this.$emit('showNotifyModal', this.dataNotify);
                })
                .catch(errors => {
                    this.showNotify = true;
                    this.dataNotify = errors;
                    this.$emit('showNotifyModal', this.dataNotify);
                })
        },
    }
}
</script>
